@import "../../../../common/variables";

.query-view-div {
    // padding: 8px 8px 8px 0;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    .query-view{
    
        // .popular-filter-part{
        //     margin-bottom: 30px;
    
        //     .popular-filter-part-content{
        //         margin-left: 10px;
        //         margin-top: 12px;
        //     }
        // }

        .search-faculty-part {
            align-self: stretch;
            margin-bottom: 5px;
            
            .search-faculty-text {
                display: flex;
                flex-direction: row;
                margin-bottom: 12px;
                .search-faculty-bullet{
                    color: var(--purple-110, #482476);
                    font-family: $campton-extra-bold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 2000;
                    line-height: normal;

                }
                .search-faculty-name {
                    margin-left: 10px;
                    color: var(--purple-110, #482476);
                    font-family: $campton-medium;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                }
                
            }
            .ant-input {
                display: flex;
                // padding: 7px 8px;
                align-items: center;
                gap: 12px;
                align-self: stretch;
                border-radius: 14px;
                background: #FFF;

            }
            .search-bottom-border {
                margin: 10px auto;
                width: 100%;
                height: 1px;
                background-color: var(--pruple-30, #B6ACD1);;
            }
        }
    
        .demographic-filter-part{
            margin-top: 10px;

            align-self: stretch;
            

            .demographic-filter-text {
                display: flex;
                flex-direction: row;
    
                .demographic-filter-bullet{
                    color: var(--purple-110, #482476);
                    font-family: $campton-extra-bold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 2000;
                    line-height: normal;

                }
                .demographic-filter-name {
                    margin-left: 10px;
                    color: var(--purple-110, #482476);
                    font-family: $campton-medium;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                }
                
            }
    
            .demographic-filter-part-content{
                // margin-left: 10px;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                // align-items: center;
                justify-content: flex-end;
                gap: 12px;
                align-self: stretch;
                
    
                label {
                    // font-size: 14px;
                    color: var(--purple-50, #9380B6);
                    font-family: $campton-book;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    padding: 3px 4px;
                }
                .ant-select-sm {
                    //font-size: 14px;
                    border-radius: 4px;
                    // border: 1px solid var(--white, #F7F7F7);
                    background: var(--cold-gray, #EAEAEA);
                    box-shadow: 2px 2px 4px 0px rgba(104, 76, 150, 0.24), -2px -2px 6px 0px rgba(255, 255, 255, 0.25);
                    color: var(--purple-110, #482476);
                    /* subtittle */
                    font-family: $campton-book;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            .demographic-bottom-border {
                margin: 10px auto;
                width: 100%;
                height: 1px;
                background-color: var(--pruple-30, #B6ACD1);;
            }
        }
    
        .innovation-filter-part{
            margin-bottom: 30px;
            
            .innovation-filter-part-content{
                margin-left: 10px;
                margin-top: 12px;
            }
        }
    
        .graphic-filter-part{
            margin-top: 10px;
            margin-bottom: 20px;
            align-self: stretch;

            .graphic-filter-text {
                display: flex;
                flex-direction: row;
    
                .graphic-filter-bullet{
                    color: var(--purple-110, #482476);
                    font-family: $campton-extra-bold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 2000;
                    line-height: normal;

                }
                .graphic-filter-name {
                    margin-left: 10px;
                    color: var(--purple-110, #482476);
                    font-family: $campton-medium;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                }
                
            }
            .graphic-filter-part-content{
                // margin-left: 10px;
                margin-top: 12px;
                display: flex;
                flex-direction: column;
                //align-items: flex-end;
                //align-items: center;
                justify-content: flex-end;
                gap: 12px;
                align-self: stretch;
                
    
                label {
                    // font-size: 14px;
                    color: var(--purple-50, #9380B6);
                    font-family: $campton-book;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    padding: 3px 4px;
                }
                .ant-select-sm {
                    border-radius: 4px;
                    // border: 1px solid var(--white, #F7F7F7);
                    background: var(--cold-gray, #EAEAEA);
                    box-shadow: 2px 2px 4px 0px rgba(104, 76, 150, 0.24), -2px -2px 6px 0px rgba(255, 255, 255, 0.25);
                    color: var(--purple-110, #482476);
                    /* subtittle */
                    font-family: $campton-book;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
            
            // .graphic-filter-part-content{
            //     margin-left: 10px;
            //     margin-top: 12px;
            // }
    
            label {
                font-size: 14px;
            }
            .ant-select-sm {
                font-size: 14px;
            }
        }

        .innovation-indicator-filter-part{
            margin-top: 10px;
            margin-bottom: 20px;
            align-self: stretch;

            .innovation-indicator-filter-text {
                display: flex;
                flex-direction: row;
    
                .innovation-indicator-filter-bullet{
                    color: var(--purple-110, #482476);
                    font-family: $campton-extra-bold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 2000;
                    line-height: normal;

                }
                .innovation-indicator-filter-name {
                    margin-left: 10px;
                    color: var(--purple-110, #482476);
                    font-family: $campton-medium;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                }
                
            }
            .innovation-indicator-filter-part-content{
                // margin-left: 10px;
                margin-top: 12px;
                display: flex;
                flex-direction: column;
                //align-items: flex-end;
                //align-items: center;
                justify-content: flex-end;
                gap: 12px;
                // align-self: stretch;
                
    
                // label {
                //     // font-size: 14px;
                //     color: var(--purple-50, #9380B6);
                //     font-family: $campton-book;
                //     font-size: 12px;
                //     font-style: normal;
                //     font-weight: 500;
                //     line-height: normal;
                //     padding: 3px 4px;
                // }
                .innovation-indicator-filter {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    // align-items: flex-start;
                    align-items: flex-start;
                    justify-content: flex-end;

                    .innovation-indicator-label {
                        display: flex;
                        justify-content: flex-end;
                        color: var(--purple-50, #9380B6);
                        font-family: $campton-book;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        padding: 3px 4px;
                    }

                    .innovation-indicator-value {
                        align-items: flex-start;
                    }
                }
            }
            
            // .graphic-filter-part-content{
            //     margin-left: 10px;
            //     margin-top: 12px;
            // }
    
            label {
                font-size: 14px;
            }
            .ant-select-sm {
                font-size: 14px;
            }
        }
    
        .confirm-button-container {
            display: flex;
            justify-content: center;
        }
    
        .confirm-button{
            // margin-top: 25px;
            // margin-left: -50px;
            margin-bottom: 50px;
        }
    }
}

