@import "./common/variables";

@media only screen and (max-width: 990px) {

  .footer-div {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 22px 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background: var(--purple-110, #482476);

    .footer-top {
      display: flex;
      padding: 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 24px;

      .logo-group {
        width: 100%;
        display: flex;
        flex-direction: row;
        .image-div {
          margin-right: 32px;
          height: 54px;
          .image {
            height: 100%;
          }
        }
      }
    }

    .footer-bottom {
      display: flex;
      padding: 0px 8px;
      align-items: flex-end;
      align-self: stretch;

      .footer-bottom-left {
        color: var(--white, #F7F7F7);
        font-family: $campton-semi-bold;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 141.667%;
      }

      .footer-bottom-right {
        flex: 1 0 0;
        .org-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          .text-div {
            color: #FFF;
            text-align: right;
            font-family: $campton-semi-bold;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 200%;
          }
        }
      }

    }
  }

  // .footer-div {
  //   width: 100%;
  //   height: 100%;
  //   font-family: $open-sans-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
  //   color: white;

  //   display: flex;
  //   flex-direction: column;
  //   flex-wrap: nowrap;
  //   justify-content: space-around;
  //   align-items: flex-start;

  //   .left {
  //     display: flex;
  //     flex-direction: column;
  //     flex-wrap: nowrap;
  //     margin: 8px 0 24px 0;
  //     .logo-group {
  //       width: 100%;
  //       display: flex;
  //       flex-direction: row;
  //       align-items: center;
  //       justify-content: center;
  //       .image-div {
  //         margin-right: 32px;
  //         height: 10vw; // 64px;
  //         .image {
  //           height: 100%;
  //         }
  //       }
  //     }
  //   }
  //   .center {
  //     margin: 24px 0;
  //   }
  //   .right {
  //     margin: 16px 0 0 0;
  //     .org-info {
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: center;
  //       align-items: flex-start;
  //       .text-div {
  //         width: 100%;
  //         margin: 4px 0;
  //         font-size: 14px;
  //         font-family: $open-sans-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
  //         color: white;
  //         text-align: left;
  //       }
  //     }
  //   }
  // }
}

@media only screen and (min-width: 990px) {
  .footer-div {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 22px 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background: var(--purple-110, #482476);

    .footer-top {
      display: flex;
      padding: 0px 8px;
      justify-content: center;
      align-items: center;
      gap: 24px;

      .logo-group {
        width: 100%;
        display: flex;
        flex-direction: row;
        .image-div {
          margin-right: 32px;
          height: 54px;
          .image {
            height: 100%;
          }
        }
      }
    }

    .footer-bottom {
      display: flex;
      padding: 0px 8px;
      align-items: flex-end;
      align-self: stretch;

      .footer-bottom-left {
        color: var(--white, #F7F7F7);
        font-family: $campton-semi-bold;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 141.667%;
      }

      .footer-bottom-right {
        flex: 1 0 0;
        .org-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          .text-div {
            color: #FFF;
            text-align: right;
            font-family: $campton-semi-bold;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 200%;
          }
        }
      }

    }

  }
  // .footer-div {
  //   width: 100%;
  //   height: 100%;
  //   font-family: $open-sans-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
  //   color: white;

  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: nowrap;
  //   justify-content: space-around;
  //   align-items: center;

  //   .left {
  //     width: 40%;
  //     display: flex;
  //     flex-direction: column;
  //     flex-wrap: nowrap;
  //     .logo-group {
  //       width: 100%;
  //       display: flex;
  //       flex-direction: row;
  //       .image-div {
  //         margin-right: 32px;
  //         height: 54px;
  //         .image {
  //           height: 100%;
  //         }
  //       }
  //     }
  //   }
  //   .center {
  //     width: 20%;
  //   }
  //   .right {
  //     width: 40%;
  //     .org-info {
  //       display: flex;
  //       flex-direction: column;
  //       justify-content: flex-end;
  //       align-items: flex-end;
  //       .text-div {
  //         margin: 2px 0;
  //         font-size: 14px;
  //         font-family: $open-sans-light, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
  //         color: white;
  //       }
  //     }
  //   }
  // }
}


// body {
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;
// }


.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: white; // #f1f1f1;
  // background: var(--purple-50, #9380B6);

  // background: radial-gradient(circle at 70% 60%, rgba(red($purple-40), green($purple-40), blue($purple-40), 0.7), rgba(255, 255, 255, 1));

}

// .content-div {
//   height: calc(100vh - 140px - 120px + 10px);
// }

.content {
  margin-top: 140px;
  width: 100vw;
  margin-bottom: 36px;

  flex-grow: 1;
}

.footer {
  // background-color: $purple-60;
  background: var(--purple-110, #482476);
  width: 100%;
  padding: 0 0;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;

  // position: fixed;
  // bottom: 0;
}

// .layout {
//   width: 100%;
//   height: 100%;
//   // width: $system-width;
//   // height: $system-height;
//   background: #ffffff;

//   .header {
//     background-color: $base-color;
//     height: 45px;
//     padding: 0px;

//     display: flex;
//     flex-wrap: nowrap;
//     justify-content: space-between;

//     .system-logo {
//       margin-left: 24px;

//       align-self: center;
//       display: flex;
//       flex-direction: row;
//       flex-wrap: nowrap;
//       align-items: center;
//       justify-content: space-around;

//       img {
//         height: 32px;
//       }
//       span {
//         margin-left: 10px;
//         font-family: $alibaba-puhuiti-bold; // $alibaba-puhuiti-regular; // $alibaba-puhuiti-light; // $alibaba-puhuiti-medium;
//         font-size: 20px;
//         color: rgb(255, 255, 255);

//       }
//     }

//     .data-selection-drawer {
//       display: flex;
//       flex-wrap: nowrap;
//       justify-content: space-between;

//       .drawer-button {
//         height: 45px;
//         width: $header-data-div-width;
//         border: none;
//         background-color: $button-color;
//         color: white;
//         font-size: 16px;

//         display: flex;
//         flex-direction: row;
//         flex-wrap: nowrap;
//         justify-content: space-between;

//         .button-div {
//           width: 220px;
//           height: 45px;
//           display: flex;
//           flex-direction: row;
//           flex-wrap: nowrap;
//           align-items: center;
//           justify-content: space-evenly;

//           .span-text {
//             color: #ffffff9e;
//           }
//           .data-text {
//             font-family: $alibaba-puhuiti-bold;
//           }
//         }

//         .dataset-name {
//           margin-right: 5px;

//           .dataset-size {
//             margin-left: 3px;
//             font-size: 12px;
//             font-family: $alibaba-puhuiti-light;
//           }
//         }
//         .group-criteria {
//           margin-left: 5px;
//         }
//       }

//       .ant-drawer {
//         margin-top: 45px;
//         display: flex;
//         justify-content: flex-end;

//         .ant-drawer-content-wrapper {
//           width: $header-data-div-width;
//           left: 1500px;
//           right: 0px;
//           .ant-drawer-header {
//             padding: 10px;
//             border-bottom: 1px solid #E9E9E9;
//             .ant-drawer-close {
//               padding: 0;
//             }
//           }
//           .ant-drawer-body {
//             font-family: $alibaba-puhuiti-regular;


//             display: flex;
//             flex-direction: column;
//             // flex-wrap: nowrap;
//             justify-content: space-between;
//             align-items: flex-start;

//             padding: 10px;
//             .selector {
//               width: 400px;
//               padding-left: 8px;

//               display: flex;
//               flex-wrap: nowrap;
//               flex-direction: row;
//               justify-content: flex-start;
//               align-items: center;

//               .selector-name {
//                 width: 120px;
//                 text-align: left;
//                 font-size: 16px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }

//   .body {
//     margin-top: 8px;
//     background: #ffffff;

//     .sider {
//       width: $system-sider;
//       background-color: #f0f2f5;

//       .collapsed-view {
//         background-color: white;
//         height: $descriptive-statistics-view-height;

//         display: flex;
//         flex-wrap: nowrap;
//         align-items: center;
//         justify-content: center;

//         .collapsed-rotate {
//           .view-title {
//             .icon {
//               font-size: 20px;
//               margin-right: 5px;
//             }
//             width: $header-data-div-width;
//             font-size: 16px;
//             font-weight: bold;
//             font-family: $alibaba-puhuiti-bold;
//           }
//           transform: rotate(90deg);
//         }
//       }

//       .ant-layout-sider-trigger {
//         position: absolute;
//         width: $descriptive-statistics-view-width;
//         height: 45px;
//         margin-bottom: 11px;
//         border-radius: 0 0 2px 2px;
//         background-color: #7e99b146;
//         &:hover {
//           background-color: $button-color;
//         }
//       }
//     }

//     .content {
//       width: 100vw;
//       height: calc(100vh - 45px); // $system-height - 45px;

//       margin-right: 8px;

//       .view-layout {
//         margin-left: 4px;
//         display: flex;
//         flex-direction: row;
//         flex-wrap: nowrap;

//         .side-two-views {
//           display: flex;
//           flex-direction: column;
//           flex-wrap: nowrap;
//         }
//       }
//     }

//     .ant-spin {
//       color: $button-color;

//       .ant-spin-dot-item {
//         background-color: $button-color;
//       }
//     }
//   }
// }
// end added by yifang

// default settings

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-page-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 140px - $header-height); // Adjust based on the total height of the header and footer
  margin-top: $header-height;
  // padding: 20px;

  @media only screen and (max-width: 990px) {
    min-height: calc(100vh - 200px); // Increased padding for smaller screens    
  }
}

.register-page-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 140px - $header-height); // Adjust based on the total height of the header and footer
  margin-top: $header-height;
  // padding: 20px;

  @media only screen and (max-width: 990px) {
    min-height: calc(100vh - 200px); // Increased padding for smaller screens    
  }
}

.delete-account-page-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 140px - $header-height); // Adjust based on the total height of the header and footer
  margin-top: $header-height;
  // padding: 20px;

  @media only screen and (max-width: 990px) {
    min-height: calc(100vh - 200px); // Increased padding for smaller screens    
  }
}