// 0. font-family
@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file);
    font-weight: normal;
    font-style: normal;
  }
}

@include font('campton-book', 'CamptonBook.otf');
@include font('campton-black', 'CamptonBlack.otf');
@include font('campton-black-italic', 'CamptonBlackItalic.otf');
@include font('campton-bold', 'CamptonBold.otf');
@include font('campton-bold-italic', 'CamptonBoldItalic.otf');
@include font('campton-book-italic', 'CamptonBookItalic.otf');
@include font('campton-extra-bold', 'CamptonExtraBold.otf');
@include font('campton-extra-bold-italic', 'CamptonExtraBoldItalic.otf');
@include font('campton-extra-light', 'CamptonExtraLight.otf');
@include font('campton-extra-light-italic', 'CamptonExtraLightItalic.otf');
@include font('campton-light', 'CamptonLight.otf');
@include font('campton-light-italic', 'CamptonLightItalic.otf');
@include font('campton-medium', 'CamptonMedium.otf');
@include font('campton-medium-italic', 'CamptonMediumItalic.otf');
@include font('campton-semi-bold', 'CamptonSemiBold.otf');
@include font('campton-semi-bold-italic', 'CamptonSemiBoldItalic.otf');
@include font('campton-thin', 'CamptonThin.otf');
@include font('campton-thin-italic', 'CamptonThinItalic.otf');

$campton-book: 'campton-book';
$campton-black: 'campton-black';
$campton-black-italic: 'campton-black-italic';
$campton-bold: 'campton-bold';
$campton-bold-italic: 'campton-bold-italic';
$campton-book-italic: 'campton-book-italic';
$campton-extra-bold: 'campton-extra-bold';
$campton-extra-bold-italic: 'campton-extra-bold-italic';
$campton-extra-light: 'campton-extra-light';
$campton-extra-light-italic: 'campton-extra-light-italic';
$campton-light: 'campton-light';
$campton-light-italic: 'campton-light-italic';
$campton-medium: 'campton-medium';
$campton-medium-italic: 'campton-medium-italic';
$campton-semi-bold: 'campton-semi-bold';
$campton-semi-bold-italic: 'campton-semi-bold-italic';
$campton-thin: 'campton-thin';
$campton-thin-italic: 'campton-thin-italic';


@include font('alibaba-puhuiti-bold', 'Alibaba-PuHuiTi-Bold.ttf');
@include font('alibaba-puhuiti-medium', 'Alibaba-PuHuiTi-Medium.ttf');
@include font('alibaba-puhuiti-regular', 'Alibaba-PuHuiTi-Regular.ttf');
@include font('alibaba-puhuiti-light', 'Alibaba-PuHuiTi-Light.ttf');
$alibaba-puhuiti-bold: 'alibaba-puhuiti-bold';
$alibaba-puhuiti-medium: 'alibaba-puhuiti-medium';
$alibaba-puhuiti-regular: 'alibaba-puhuiti-regular';
$alibaba-puhuiti-light: 'alibaba-puhuiti-light';

@include font('open-sans-extrabold', 'open-sans-extrabold.ttf');
@include font('open-sans-bold', 'open-sans-bold.ttf');
@include font('open-sans-bold-italic', 'OpenSansBoldItalic.ttf');
@include font('open-sans-semi-bold', 'OpenSansSemibold.ttf');
@include font('open-sans-regular', 'open-sans-regular.ttf');
@include font('open-sans-regular-italic', 'OpenSansItalic.ttf');
@include font('open-sans-light', 'OpenSansLight.ttf');
@include font('open-sans-light-italic', 'OpenSansLightItalic.ttf');
$open-sans-extrabold: 'open-sans-extrabold';
$open-sans-bold: 'open-sans-bold';
$open-sans-bold-italic: 'open-sans-bold-italic';
$open-sans-semi-bold: 'open-sans-semi-bold';
$open-sans-regular: 'open-sans-regular';
$open-sans-regular-italic: 'open-sans-regular-italic';
$open-sans-light: 'open-sans-light';
$open-sans-light-italic: 'open-sans-light-italic';


@include font('Ubuntu-bold', 'Ubuntu-Bold.ttf');
@include font('Ubuntu-medium', 'Ubuntu-Medium.ttf');
@include font('Ubuntu-regular', 'Ubuntu-Regular.ttf');
@include font('Ubuntu-light', 'Ubuntu-Light.ttf');
$Ubuntu-bold: 'Ubuntu-bold';
$Ubuntu-medium: 'Ubuntu-medium';
$Ubuntu-regular: 'Ubuntu-regular';
$Ubuntu-light: 'Ubuntu-light';


// 0. unified styles
$title-font-size: 16px;
$subtitle-font-size: 14px;
$plain-text-font-size: 12px;
$header-height: 88px;
$header-height-collapse: 80px;
$footer-height: 140px;

$subtitle-height: 22px;
$subtitle-margin: 20px;
$subtitle-antchor-width: 5px;

$box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);

// 1. view sizes
$header-data-div-width: 420px; // 350px;
// $system-width: 1920px;
// $system-height: 1080px;
$system-sider: 450px;
$system-content: calc(1920px - $system-sider);

$descriptive-statistics-view-width: 450px;
$descriptive-statistics-view-height: 1010px;
$descriptive-statistics-view-body-height: calc($descriptive-statistics-view-height - 45px);
$descriptive-statistics-uoa-individual-height: 475px;
$descriptive-statistics-uoa-individual-body-height: calc($descriptive-statistics-uoa-individual-height - $subtitle-height - $subtitle-margin*2);
$descriptive-statistics-uoa-individual-flow-div-width: 180px;
$descriptive-statistics-uoa-individual-flow-div-height: 120px;
$descriptive-statistics-uoa-individual-flow-div-header-height: 23px;
$descriptive-statistics-uoa-individual-flow-div-body-height: calc($descriptive-statistics-uoa-individual-flow-div-height - $descriptive-statistics-uoa-individual-flow-div-header-height);

$descriptive-statistics-uoa-person-year-height: 440px;
$descriptive-statistics-uoa-person-year-inside-div-height: 380px;

$main-view-width: 1440px;
$main-view-height: 690px;
$main-view-subtitle-group-height: 40px;
$main-view-main-body-height: 600px;
$main-view-iv-list-div-width: 353px;
$main-view-linked-line-width: 50px;
$main-view-impact-div-width: 1000px;
$main-view-param-folder-div-width: 27px;
$main-view-param-folder-title-width: 164px;
$main-view-param-folder-div-unfold-width: 320px;
$main-view-param-folder-div-unfold-height: 900px; // 640px;
$mainview-ivlist-row-height: 32px;
$mainview-ivlist-row-margintop: 10px;
$mainview-ivlist-row-eventnameunit-width: 143px;
$mainview-ivlist-row-horizonchartunit-width: 200px;
$mainview-ivlist-row-horizonchartunit-height: 32px;
$mainview-ivlist-row-horizonchartunit-bar-width: 20px;

// $mainview-impacttimeline-background-width: 990px;
$mainview-impacttimeline-stage-width: 166px;
$mainview-impacttimeline-stage-horizontal-margin: 12px;

$mainview-stage-title-group-height: 54px;
$mainview-careerflow-group-height: 315px;
$mainview-careerflow-group-margintop: 19px;
$mainview-glyph-linechart-height: 210px;
$mainview-impacttimeline-height: calc($mainview-stage-title-group-height + $mainview-careerflow-group-height + $mainview-glyph-linechart-height + 20px);
$mainview-glyph-linechart-unit-height: 100px;
$main-view-clusterlabel-div-width: 28px;
$main-view-clusterlabel-div-height: 92px;
$main-view-clusterlabel-div-title-height: 20px;
$main-view-clusterlabel-div-body-height: calc($main-view-clusterlabel-div-height - $main-view-clusterlabel-div-title-height);

$prediction-view-width: 1440px;
$prediction-view-height: 310px;
$prediction-view-sequencelist-div-width: 380px;
$prediction-view-sankeydiagram-div-width: 1035px;
$prediction-view-subdiv-height: 245px;

// 1.1 margins
$descriptive-statistics-view-left-right-margin: 13px;

// 2. colors
$base-color: #404040;
$button-color: #7E99B1; // #7E8FA6;
$table-color: #434343;
$highlight-color: #E9CA9B;

$event-button-border-color: #CED5DE;
$event-button-background-color: #F3F5F7;

$marriage-event-color: #ADD8F7;
$childbearing-event-color: #FCCCA7;
$migration-event-color: #C3B9FA;
$education-event-color: #A7E1C4;
$first-job-event-color: #FFE9A7;

$div-border-grey: #D9D9D9;

$glyph-coef-stroke-negative: #C69480;
$glyph-coef-stroke-positive: #93A49E;
$glyph-ecoef-stroke: #93A49E;
$glyph-donut-background: #DFDFDF;
$glyph-donut-stroke: #B9C3D0;


$purple-10: #C6C1DC;
// $purple-10: #fed9ca;
$kellogg-purple: #4B1D86;
$purple-80: #624D9C;
$purple-60: #8073AE;
$purple-40: #A89FC9;

// color
$green-light: #c5d6d0;
$green-regular: #609e90;
$green-dark: #426f65;
$red-light: #e9d1c0;
$red-regular: #cfa495;
$red-dark: #c49289;
$blue-light: #c6d0d9;
$blue-regular: #a2b6c3;
$blue-bold: #65859a;
$blue-dark: #718592;
$blue-heavy: #0f293a;
$black-base: #3b3b3b; // #0e1e29;