@import "../../../common/variables";

@media only screen and (max-width: 990px) {
  .org {
    width: 100%;
    margin-top: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .org-div {
      padding: 8px 16px;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      .unit-div {
        margin: 4px;
      }
      .image-div {

        .image {
          // width: 108px;
          height: 40px;
        }
      }
    }
  }
}
@media only screen and (min-width: 990px) {
  .org {
    width: 100%;
    margin-top: 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .org-div {
      padding: 8px 16px;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      .unit-div {
        margin: 4px;
      }
      .image-div {

        .image {
          // width: 108px;
          height: 40px;
        }
      }
    }
  }
}

.about-div {
  width: 100%;
  padding: 0 16%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  .line {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .researcher-div {
      padding: 16px 5%;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;

      .unit-div {
        // margin: 4px;
      }

      .image-div {
        // width: 12%;

        .image {
          width: 100px;
        }
      }
      .name-div {
        margin-top: 10px;
        transition: 0.5s;
        cursor: pointer;
        a {
          font-family: $open-sans-regular;
          color: #4e4e4e;
          text-decoration: underline;
        }
        &:hover {
          -webkit-filter: drop-shadow( 0px 0px 4px rgba(165, 165, 165, 0.9));
          filter: drop-shadow( 0px 0px 4px rgba(165, 165, 165, 0.9));
        }
      }
      .affiliation-div {
        margin-top: 4px;
        font-size: 10px;
        font-family: $open-sans-regular;
        line-height: 1.6em;
        color: #624D9C;
      }
    }
  }
}
