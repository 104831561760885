@import "../../../common/variables";

@media only screen and (max-width: 990px) {
  .ant-layout-header {
    width: 100%;
    height: $header-height-collapse;
    background-color: white;
  }
  .header-left-div {
    .logo {
      img {
        height: 56px;
      }
    }
  }
  #header-right-div {
    display: none;
  }
}
@media only screen and (min-width: 990px) {
  .ant-layout-header {
    width: 100%;
    height: $header-height;
    background-color: white;
    background: var(--white, #F7F7F7);
  }
  .header-left-div {
    .logo {
      img {
        height: 72px;
      }
    }
  }
  #header-right-div-collapse {
    display: none;
  }
}

.ant-row {
  .header-left-div {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;

    .logo {
      margin-left: 16px;
      margin-right: 16px;

      img {
        // height: 56px;
        transition: all 0.5s;
        vertical-align: middle;
      }
    }
  }

  .header-right-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    .right-div-inside {
      height: 48px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      // justify-content: flex-end;
      // align-items: center;

      .nav-button-unit,
      .nav-button-unit *,
      .nav-button-unit::after,
      .nav-button-unit::before,
      .nav-button-unit:before {
        border: 0 solid;
        box-sizing: border-box;
      }
      
      .nav-button-unit {
        // margin: 0 8px;
        padding: 12px;

        display: flex;
        align-items: center;
        justify-content: center;

        // background-color: #fff; /* Default white background */
        background: var(--white, #F7F7F7);
        color: #000; /* Default text color */
        cursor: pointer;
        font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
          'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
          'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-size: 100%;
        font-weight: 900;
        text-transform: uppercase;
        border-radius: 0; /* Ensure the shape is rectangular */
        overflow: hidden;
        position: relative;
        transition: color 0.3s ease; /* Smooth transition for text color */
      }
      
      .nav-button-unit:disabled {
        cursor: default;
      }
      
      .nav-button-unit:before {
        background: $purple-80; /* Purple background on hover */
        content: '';
        display: flex;

        height: 100%;
        left: -100%;
        position: absolute;
        top: 0;
        // transform: translateX(-100%);
        transform: skew(0deg) translateX(-20%);
        // transform: skew(0deg) translateX(-20%);
        transition: transform 0.3s ease;
        width: 120%;
        z-index: 0;
      }
      
      .nav-button-unit:hover:before {
        transform: skew(45deg) translateX(75%);
        // transform: translateX(75%);
        // transform: skew(45deg) translateX(75%);
      }
      
      .nav-button-unit a {
        color: white;
        position: relative;
        z-index: 1; /* Ensure the text is above the background animation */
      }
      
      .nav-button-unit:hover {
        color: #fff; /* Change text color on hover */
      }
      .nav-button-unit:hover .ant-anchor-link-title {
        color: #fff;
      }
      

      .ant-anchor-link {
        .ant-anchor-link-title {
          color: var(--purple-110, #482476);
          font-family: $campton-bold;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .ant-anchor-link-active {
        .ant-anchor-link-title-active {
          color: $purple-80;
          text-decoration-line: underline;
        }
      }
      
    }

    .logout-button {
      display: flex;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .logout-logo-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .logout-logo {
          width: 20px;
          height: 16px;
          fill: var(--purple-110, #482476);
        }
      }
      .logout-text {
        width: 68px;
        height: 16px;
        color: var(--purple-110, #482476);
        font-family: $campton-medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
  
      .ant-btn {
        border-radius: 20px;
        border: 2px solid var(--purple-110, #482476);
        background-color: white;
  
        &:hover {
          background-color: #4D4593;
          color: #FFFFFF;
        }
  
        .anticon {
          margin-right: 1px;  // Space between the icon and the text
        }
      }

    }

    
  }

  .header-right-div-collapse {
    display: flex;
    justify-content: flex-end;
    .menu {
      font-size: 28px;
      color: $purple-60;
      // color: white;
    }
  }

  .collapse-menu-div.active {
    opacity: 1;
    right: 0;
  }

  .collapse-menu-div {
    opacity: 0;
    width: 240px;
    background-color: $purple-80;

    position: absolute;
    top: 0;
    right: -240px;
    padding: 8px 0;
    z-index: 99;
    box-shadow: 3px 3px 8px rgba(52, 52, 52, 0.2);

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .close-div {
      height: 32px;
      width: 100%;
      padding: 0 8px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .menu {
        font-size: 18px;
        color: white;
        cursor: pointer;

        &:hover {
          transform: scale(1.5);
          color: black;
          transition: all 0.5s;
        }
      }
    }
    .collapse-menu-div-unit {
      padding: 8px;
      a {
        letter-spacing: 2px;
        color: white;
          font-family: $open-sans-regular, 'Open Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
      }
    }
    transition: all .5s;
  }

  .header-right-div-collapse-div {
    position: fixed;
    top: 0;
    right: 0;
  }
}


.ant-layout-header {
  width: 100%;
  // height: $header-height;
  position: fixed;
  top: 0px;

  z-index: 100;

  // background-color: white;
  // background-color: $purple-40;
  box-shadow: 3px 3px 8px rgba(52, 52, 52, 0.2);
  padding: 24px;
  transition: all .5s;
}
.ant-divider-with-text.ant-divider-with-text {
  margin: 0;
}

// .right-div-inside {
  
// }

// .header-right-div {
//   .right-div-inside {
//     .ant-affix {
      
//     }
//     .ant-anchor-wrapper {
//       .ant-anchor {
//         .ant-anchor-ink {
//           background-color: $purple-80;
//         }
//         .ant-anchor-ink-visible {
//           background-color: $purple-80;
//         }
//       }
//     }
//   }
// }


