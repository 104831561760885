@import "../../../common/variables";

.page-panel {
  width: 100%;
  height: 100%;
  margin: 4px;

  background-color: white;
  border-radius: 16px;

  // border: 1px solid rgba(216, 216, 216, 0.7);
  
  // box-shadow: 0 0 24px 0 rgba(185,185,185,0.50);
  // backdrop-filter: blur(50px);
  // background-color: rgb(255, 255, 255);
  // border: 2px solid rgba(255, 255,255, 0.7);

  border-radius: 8px;
  // background: #FFF;

  .panel-header {
    display: flex;
    flex-direction: column;
    
    .panel-header-content {
      // height: 45px;
      // border-bottom: 1px solid #E9E9E9;
      padding-left: 13px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      .left-align {
        width: 300px;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;

        .panel-icon {
          font-size: 18px;
          margin-right: 5px;
          // color: $button-color;
        }

        .panel-title {
          font-size: 20px;
          font-weight: bold;
          font-family: $alibaba-puhuiti-bold, 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
          // letter-spacing: 2px;
          // font-family: $open-sans-bold, 'Gill Sans MT', 'Calibri', 'Trebuchet MS', sans-serif;
        }
      }
      .right-align {
        //width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;

        .select-div {
          //width: 100px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: flex-start;

          .select-label {
            margin-right: 2px;
            color: var(--purple-50, #9380B6);
            text-align: center;
            /* subtittle */
            font-family: $campton-semi-bold;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .ant-select {
            display: flex;
            
            // width: 236px;
            height: 20px;
            //padding: 7px 8px;
            justify-content: flex-end;
            align-items: center;
            //gap: 12px;
            border-radius: 4px;
            border: 1px solid var(--white, #F7F7F7);
            background: var(--cold-gray, #EAEAEA);
            box-shadow: 2px 2px 4px 0px rgba(104, 76, 150, 0.24), -2px -2px 6px 0px rgba(255, 255, 255, 0.25);  
          }
          
        }

      }
      .right-align-mainview {
        width: 1110px;

        .slider-group {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;

          .slider-unit {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            margin: 0 5px;

            .slider-name {
              // width: 190px;
              font-size: 12px;
              display: flex;
              flex-wrap: nowrap;
              justify-content: flex-start;
            }
            .slider-body {
              width: 150px;

              .ant-select {
                width: 150px;
                padding: 0 10px;

                .ant-select-selector {
                  border-radius: 5px;
                  height: 26px;
                  margin: 4px 0;
                  font-size: $plain-text-font-size;

                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                }
                .ant-select-arrow {
                  margin-right: 10px;
                }
              }
              .ant-slider {
                .ant-slider-track {
                  background-color: $button-color;
                }
                .ant-slider-handle {
                  border: 2px solid $button-color;
                }
              }
              .ant-switch-checked {
                background-color: $button-color;
              }
            }
            .legend-income-quantile-unit {
              width: 30px;
              height: 20px;
              border-radius: 5px;
              border: 1.5px solid rgba(255, 255, 255, 0.486);
              margin: 0 2px;

              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: center;
            }
            .legend-pvalue-unit {
              width: 20px;
              height: 20px;
              border-radius: 5px;
              border: 1.5px solid rgba(255, 255, 255, 0.486);
              // margin: 0 2px;
            }
          }
        }

      }
    }

  }

  .panel-body {
    height: calc(100% - 45px); 
    // padding: 15px;
    // display: flex;
    // flex-wrap: wrap;
  }
}
