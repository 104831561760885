@import "../../../../common/variables";

.researcher-details-container {
    padding: 20px;
    display: flex;
    width: calc(80vw - 96px);
    // width: 70vw;
    // height: 100vh;
    flex-direction: column;
    margin-left: 20px;
    overflow:hidden;
    min-height: 100vh;

    position: relative;
    gap: 16px;

  }

  .researcher-details-custom-spin{
    margin-top: 140px;
    position: absolute;
    left: 50%; 
    transform: translate(0%, -50%);
    
  }

  .researcher-details-card {
    margin-bottom: -20px;

  }


  // .basic-info-header {
  //   margin-top: 0px;
  //   margin-bottom: 10px;
  // }
  
  // .basic-info {
  //   display: flex;
  //   // margin-bottom: 20px;
  //   gap: 20px;
  //   // height: 195px;
  //   margin-left: -19px;
  // }
  
  
  // .info-card {
  //   // flex: 1;
  //   min-width: 300px;
  // }
  
  // .bar-plot {
  //   height: 100%;
    
  //   margin-top: 18px;
  //   flex: 1;
  //   min-width: 300px;
  //   min-height: 225px;
    
  //   // background: #a89fc91c;
  //   background-color: rgba($purple-10, 0.2); // 50% opacity
  //   // background-color: $purple-10;
  //   border-radius: 3px;
  //   display: flex;
  //   justify-content: center;
  //   // align-items: flex-end;
  //   // padding-bottom: 8px;
  // }
  
  .papers-section {
    // height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    margin-top: 32px;
    padding-top: 32px;
    flex-direction: column;
    // align-items: center;
    gap: 16px;
    align-self: stretch;
  
    .papers-header {
      align-self: stretch;
      color: var(--purple-110, #482476);
      font-family: $campton-medium;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-bottom: 10px;
      border-bottom: 1px solid #B6ACD1; //
    }

    // .papers-header-filter {
      // display: flex;
      // // justify-content: space-between;
      // align-items: center;
      // padding-left:10px;
      // padding-right: 10px;

      // margin-top: 0px;
      // margin-bottom: 0px;
      // background-color: rgba($purple-10, 0.2);
      // border-radius: 15px;
    
      .paper-filter-part{
        margin-bottom: -20px;
        display: flex;
        // margin-top: 10px;;
        // margin-left: 0px;
        justify-content: flex-start;
        flex-direction: column;
        // align-items: center;

        .paper-filter-part-top{
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          justify-content: flex-start;
          gap: 24px;
          flex: 1 0 0;

          .filter-div {
            // width: 200px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            // align-items: flex-start;
            align-items: flex-start;
            justify-content: flex-start;
  
            .filter-label {
              margin-top: 10px;
              margin-left: 10px;
              color: var(--purple-50, #9380B6);
              text-align: left;
              /* subtittle */
              font-family: $campton-semi-bold;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .filter-value {
              align-items: flex-start;

            }
          }
          .custom-switch.ant-switch-checked {
            background-color: #624D9C; 
          }

        }

        .paper-filter-part-bottom{
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          justify-content: flex-start;
          gap: 24px;
          flex: 1 0 0;

          .filter-div {
            // width: 200px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            // align-items: flex-start;
            align-items: flex-start;
            justify-content: flex-start;
  
            .filter-label {
              margin-top: 10px;
              margin-left: 10px;
              color: var(--purple-50, #9380B6);
              text-align: left;
              /* subtittle */
              font-family: $campton-semi-bold;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .filter-value {
              align-items: flex-start;

            }
            .ant-select {
              display: flex;
              height: 20px;
              justify-content: flex-end;
              align-items: center;
              border-radius: 4px;
              border: 1px solid var(--white, #F7F7F7);
              background: var(--cold-gray, #EAEAEA);
              box-shadow: 2px 2px 4px 0px rgba(104, 76, 150, 0.24), -2px -2px 6px 0px rgba(255, 255, 255, 0.25);  
            }
          }
          .custom-switch.ant-switch-checked {
            background-color: #624D9C; 
          }
          .reset-button{
            background-color: #A495C3;
            border-color: #A495C3;
            font-family: $campton-medium;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: none;
            box-shadow: none;
          
            &:hover {
              background-color: #624D9C;
              border-color: #624D9C;
            }
          }
          .download-button {
            background-color: #A495C3;
            border-color: #A495C3;
            font-family: $campton-medium;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: none;
            box-shadow: none;
          
            &:hover {
              background-color: #624D9C;
              border-color: #624D9C;
            }
          }
          .csv-link {
            display: none;
          }
        }
        

        // .paper-filter-part-content {
        //   display: flex;
        //   flex-direction: row;
        //   // align-items: center;

        //   .custom-switch.ant-switch-checked {
        //     background-color: #624D9C; 
        //   }
        // }

        }
        // .select-group {
        //   display: flex;
        //   gap: 10px;
        // }
        // label {
        //   // font-size: 14px;
        //   color: var(--purple-50, #9380B6);
        //   font-family: $campton-semi-bold;
        //   font-size: 12px;
        //   font-style: normal;
        //   font-weight: 500;
        //   line-height: 141.667%;
        //   // padding: 3px 4px;
        // }
      // }
  
    .papers-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      // gap: 20px;
      
      overflow-y: auto;
      max-height: 560px; // Adjust this value as needed

      // Add this pseudo-element
      &::after {
        content: '';
        flex-grow: 999999999;
      }
    }
  }
  
  