@import "../../../common/variables";

@media only screen and (max-width: 990px) {
  .teaser {
    z-index: 2;
    margin-top: $header-height-collapse;
  }
  .system-demo-button-div {
    visibility: hidden;
  }
}
@media only screen and (min-width: 990px) {
  .teaser {
    z-index: 1;
    margin-top: $header-height;
  }
  .system-demo-button-div {
    .button {
      // width: 64px;
      height: 32px;
      padding: 12px;
      border-radius: 6px;
      background-color: white;
      box-shadow: 16px 16px 16px rgba(51,51,51,.5);
      color: rgb(59, 59, 59);

      font-size: 12px;
      font-family: $open-sans-regular;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
    }
  }
}


.teaser {
  z-index: 2;

  width: 100%;
  height: 30vw; // 400px;
  position: relative;

  // margin-top: $header-height;

  .background-img {
    width: 100%;
    height: 32vw; // 400px; // 100%;
    position: absolute;
    z-index: 1;

    .teaser-content {
      width: 100%;
      height: 32vw; // 400px; // 100%;
      // filter: brightness(100%);

      object-fit: cover;
    }
  }
  .foreground-div {
    width: 100%;
    height: 32vw; // 100%;

    position: absolute;
    z-index: 2;

    padding-left: 6%;
    padding-right: 6%;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .row-div {
      // height: 100%;
      .teaser-left-div {
        height: 100%;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        justify-content: center;
        align-items: flex-start;

        .div-unit {
          margin: 16px 0;
        }

        .system-name-div {
          width: 100%;
          .logo-text-img {
            width: 70%;
          }
        }
        .system-slogan-div {
          font-size: 1.6vw;
          font-family: $Ubuntu-bold;
          color: rgba(255, 255, 255, 0.874);
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          letter-spacing: 2px;
        }
        // .system-demo-button-div {
        //   .button {
        //     // width: 64px;
        //     height: 32px;
        //     padding: 12px;
        //     border-radius: 6px;
        //     background-color: white;
        //     box-shadow: 16px 16px 16px rgba(51,51,51,.5);
        //     color: rgb(59, 59, 59);

        //     font-size: 12px;
        //     font-family: $open-sans-regular;

        //     display: flex;
        //     flex-direction: row;
        //     flex-wrap: nowrap;
        //     align-items: center;
        //     justify-content: center;
        //   }
        // }
      }
      .teaser-right-div {
        height: 100%;
        width: 100%;

        .ui-background-content {
          width: 100%;
          margin-top: 30%;
          // opacity: 0.8;
        }
      }
    }
  }
}