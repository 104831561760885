@import "../../../../../common/variables";

.paperDetailCardDiv{
    // width:458.5px;
    // margin-left: 16px;
    display: flex;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: var(--white, #F7F7F7);

    position: relative;
    height: 193px;
    flex-direction: column;


    // &:hover {
    //     cursor: pointer;
    //     transition: all 0.5s;
    //     box-shadow: 0 2px 8px 0 rgb(233 231 242);
    //     background: url(<../../../../../../../../assets/10-people-finder-highlight-bg/bg-paper.png) lightgray 50% / cover no-repeat;
    //     //background: linear-gradient(135deg, #a093b6 0%, #ffffff 100%);
    // }

    .card-top-div {
        display: flex;
        padding: 16px 12px;
        flex-direction: row;
        justify-content: space-between;
        //align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
        width: 100%;
        
        .card-metadata-and-title{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;

            .card-metadata {
                display: flex;
                height: 22px;
                justify-content: row;
                align-items: center;
                gap: 12px;
                //align-self: stretch;
                width: 100%;
                white-space: nowrap;
    
                .card-year {
                    display: flex;
                    padding: 4px 8px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background: var(--purple-50, #9380B6);
    
    
                    .card-year-text {
                        width: 38px;
                        height: 14px;
                        color: var(--white, #F7F7F7);
                        font-family: $campton-semi-bold;
                        font-size: 16px;
                        line-height: normal;
                    }
                }
    
                .card-mag {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    flex: 1 0 0;
                    overflow: hidden;
                    color: var(--green, #72A84D);
                    text-overflow: ellipsis;
                    font-family: $campton-book;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    font-family: $campton-semi-bold;
                    white-space: nowrap;
    
                    .card-mag-name {
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        color: var(--purple-110, #482476);
                        text-overflow: ellipsis;
                        font-family: $campton-semi-bold;
                        font-size: 12px;
                        line-height: normal;
                    }
                }
            }
            .card-title {
                width: 100%;
                .card-title-text{
                    height: 38px;
                    align-self: stretch;
    
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
    
                    overflow: hidden;
                    color: var(--purple-110, #482476);
                    font-family: $campton-semi-bold;
                    font-size: 16px;
                    line-height: normal;
                }
                
            }
        }
        .card-score-container{
            display: flex;
            width: 72px;
            height: 76px;
            padding: 0px 32px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            //align-self: stretch;
            border-radius: 16px;
            background: var(--purple-10, #E4E0EE);
            //margin-left: 300px;
    
            .card-score-badge {
                color: var(--green, #72A84D);
                font-family: $campton-bold;
                font-size: 19px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }

    .card-bottom{
        display: flex;
        padding: 16px 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;

        .card-abstract-title{
            color: var(--purple-110, #482476);
            font-family: $campton-semi-bold;
            font-size: 16px;
            margin-bottom: 12px;
        }

        .card-abstract-text{
            font-family: $campton-book;
            color: #684C96;
            font-size: 12px;
            line-height: 17px;
            white-space: wrap;
        }
        // .card-author {
        //     overflow: hidden;
        //     color: var(--purple-110, #482476);
        //     text-overflow: ellipsis;
        //     white-space: nowrap;
        //     font-family: $campton-book;
        //     font-size: 12px;
        //     font-style: normal;
        //     font-weight: 500;
        //     line-height: normal;
        // }

        .card-doi {
            align-self: stretch;
            white-space: nowrap;
            text-align: right;

            .card-doi-attr {
                color: var(--green, #72A84D);
                text-align: right;
                font-family: $campton-book;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .card-doi-arrow {
                color: var(--purple-110, #482476);
                font-family: $campton-book;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .card-doi-text {
                color: var(--purple-110, #482476);
                font-family: $campton-book;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-decoration-line: underline;
            }
        }

    }
}