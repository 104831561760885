@import "../../../../common/variables";
.query-view-div {
    padding: 8px 8px 8px 0; 
    .query-view{
        margin: 16px;
        
        // .popular-filter-part{
        //     margin-bottom: 30px;
    
        //     .popular-filter-part-content{
        //         margin-left: 10px;
        //         margin-top: 12px;
        //     }
        // }

        .search-paper-part {
            align-self: stretch;
            margin-bottom: 5px;
            
            .search-paper-text {
                display: flex;
                flex-direction: row;
                margin-bottom: 12px;
                .search-paper-bullet{
                    color: var(--purple-110, #482476);
                    font-family: $campton-extra-bold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 2000;
                    line-height: normal;

                }
                .search-paper-name {
                    margin-left: 10px;
                    color: var(--purple-110, #482476);
                    font-family: $campton-medium;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                }
                
            }
            .ant-input {
                display: flex;
                // padding: 7px 8px;
                align-items: center;
                gap: 12px;
                align-self: stretch;
                border-radius: 14px;
                background: #FFF;

            }
        }
    
        .paper-filter-part{
            //margin-bottom: 30px;
            margin-top: 10px;
            align-self: stretch;
            .paper-filter-text {
                display: flex;
                flex-direction: row;
    
                .paper-filter-bullet{
                    color: var(--purple-110, #482476);
                    font-family: $campton-extra-bold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 2000;
                    line-height: normal;

                }
                .paper-filter-name {
                    margin-left: 10px;
                    color: var(--purple-110, #482476);
                    font-family: $campton-medium;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                }
                
            }
    
            .paper-filter-part-content{
                // margin-left: 10px;
                // margin-top: 12px;
                display: flex;
                margin-top: 12px;
                display: flex;
                flex-direction: column;
                // align-items: center;
                justify-content: flex-end;
                gap: 12px;
                align-self: stretch;
                
                label {  
                    color: var(--purple-50, #9380B6);
                    font-family: $campton-book;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    padding: 3px 4px;
                }
            }
        }
    
        .innovation-filter-part{
            margin-bottom: 30px;
            
            .innovation-filter-part-content{
                margin-left: 10px;
                margin-top: 12px;
            }
        }
    
        .graphic-filter-part{
            margin-bottom: 20px;
            
            .graphic-filter-part-content{
                margin-left: -10px;
                margin-top: 12px;
            }
        }
    
        .confirm-button-container {
            display: flex;
            justify-content: center;
        }
    
        .confirm-button{
            // margin-top: 25px;
            // margin-left: -50px;
            margin-bottom: 50px;
        }
    }
    
}
