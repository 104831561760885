@import "../../../../common/variables";

.paper-details-container {
    padding: 20px;
    padding-right: 25px;
    display: flex;
    width: calc(80vw - 96px);
    // width: 70vw;
    // height: 100vh;
    flex-direction: column;
    margin-left: 20px;
    overflow:hidden;
    min-height: 100vh;

    position: relative;
  
    .paper-details-custom-spin{
      margin-top: 140px;
      position: absolute;
      left: 50%; 
      transform: translate(0%, -50%);
      
    }
    
    .basic-info {
      display: flex;
      // margin-bottom: 20px;
      gap: 20px;
      // height: 195px;
      margin-left: -19px;
    }
    
    
    .info-card {
      // flex: 1;
      min-width: 300px;
    }
    
    .papers-section {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
    
      .papers-header {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 20px;
          margin-bottom: 10px;
          font-family: $campton-semi-bold;
          font-size: 16px;
          gap: 5px;
      
          .papers-header-title {
            margin: 0;
            color: #482476;
          }
          .papers-header-value{
            color: #72A84D;
          }
        }
  
        .papers-header-filter {
          display: flex;
              justify-content: space-between;
              align-items: center;
              padding-left:10px;
              padding-right: 10px;
  
              margin-top: 0px;
              margin-bottom: 25px;
              background-color: rgba($purple-10, 0.2);
              //border-radius: 15px;
        
              .paper-filter-part{
                display: flex;
                margin-top: 10px;;
                margin-left: 0px;
                justify-content: space-between;
                align-items: center;
      
                .paper-filter-part-content {
                  display: flex;
                  flex-direction: row;
      
                  .custom-switch.ant-switch-checked {
                    background-color: #624D9C; 
                  }
                }
      
              }
              .select-group {
                display: flex;
                gap: 10px;
              }
            }
    
      .papers-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        // gap: 20px;
        
        overflow-y: auto;
        max-height: 560px; // Adjust this value as needed
  
        // Add this pseudo-element
        &::after {
          content: '';
          flex-grow: 999999999;
        }
      }
    }
  }


  
  