.researcher-view-div{
  min-height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .back-and-breadcrumb{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .breadcrumb{
      margin-left: 32px;
    }
  }


  .paper-view-custom-spin{
    margin-top: 230px;
    position: absolute;
    left: 50%; 
    transform: translate(0%, -50%);
  }

  .is-empty{
    width: 100%;
    margin-top: 50px;
  }

  .idea-finder-paper-card-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  
    max-height: 1080px;
    overflow-y: auto;
    justify-content: space-evenly;
  }

  .paper-view-pagination{
    // display: flex;
    // justify-content: right; 
    // margin-bottom: 30px; 
    // margin-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    margin-bottom: 30px; 
  }
}

