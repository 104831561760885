@import "../../../../common/variables";

.researcherDetailCardDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    border-radius: 8px;
    border: 1px solid var(--white, #F7F7F7);
    background: var(--white, #F7F7F7);

    .researcher-left-div {
        display: flex;
        padding: 24px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        background: var(--pruple-30, #B6ACD1);
        border-radius: 8px;

        .researcher-img-container {
            width: 148px;
            height: 148px;
            flex-shrink: 0;
            border-radius: 8px;
            border: 2px solid var(--purple-10, #E4E0EE);

            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            

            .researcher-img {
                font-size: 80px;
                color: var(--purple-10, #E4E0EE);

                // display: flex;
                // justify-content: center; // Centers the image horizontally
                // align-items: center; // Centers the image vertically
                // overflow: hidden;
                // position: absolute;
                
            }

            .researcher-photo {
                overflow: hidden;
                margin-top: 30%;
                object-fit: cover;
                width: 100%;
                height: auto;
                // clip-path: inset(10% 0 0 0)
            }
        }

        .researcher-name {
            color: var(--purple-110, #482476);
            font-family: $campton-semi-bold;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .researcher-actions {
            display: flex;
            padding: 0px 20px;
            justify-content: center;
            align-items: center;
            gap: 24px;
            align-self: stretch;

            .researcher-action-icon {
                display: flex;
                width: 30px;
                height: 30px;
                padding: 6.857px;
                justify-content: center;
                align-items: center;
                border-radius: 6.857px;
                border: 0.857px solid var(--purple-10, #E4E0EE);
                background: var(--purple-110, #482476);
                box-shadow: 1.714px 1.714px 3.429px 0px rgba(1, 1, 1, 0.24), -1.714px -1.714px 5.143px 0px rgba(255, 161, 161, 0.25);

                .action-icon {
                    color: #ffffff;
                    width: 16.749px;
                    height: 16.172px;
                    flex-shrink: 0;
                    margin-left: 3px;
                }
            }
        }
    }

    .researcher-center-div {
        display: flex;
        padding: 0px 8px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
        align-self: stretch;

        .researcher-school {
            // width: 146px;
            height: 14px;

            overflow: hidden;
            color: var(--purple-110, #482476);
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: $campton-semi-bold;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .researcher-demographic {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;

            .researcher-gender {
                display: flex;
                width: 32px;
                height: 32px;
                padding: 7px 8px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: var(--green, #72A84D);

                .researcher-gender-letter {
                    width: 20px;
                    height: 22px;
                    flex-shrink: 0;
                    color: var(--white, #F7F7F7);
                    font-family: $campton-medium;
                    font-size: 23px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
            .researcher-tenure {
                display: flex;
                height: 28px;
                flex: 1 0 0;
                flex-direction: column;
                justify-content: center;

                overflow: hidden;
                color: var(--purple-110, #482476);
                text-overflow: ellipsis;
                white-space: nowrap;
                /* subtittle */
                font-family: $campton-semi-bold;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 141.667%;
            }
        }
        .scisci-index-div-group {
            display: flex;
            padding-top: 8px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;

            .scisci-index {
                display: flex;
                height: 32px;
                padding: 7px 8px;
                justify-content: flex-end;
                align-items: center;
                gap: 12px;
                align-self: stretch;
                border-radius: 14px;
                background: #FFF;

                .pound-sign {
                    width: 9.423px;
                    height: 10px;
                    fill: var(--purple-50, #9380B6);
                }
                .index-name {
                    flex: 1 0 0;
                    color: var(--purple-50, #9380B6);
                    /* subtittle */
                    font-family: $campton-semi-bold;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 141.667%; /* 17px */
                }
                .index-value {
                    color: var(--green, #72A84D);
                    font-family: $campton-medium;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
        }
    }
    
    .researcher-right-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
        align-self: stretch;
        // width: 475.536px;
        // height: 200px;

        .bar-plot {
            // height: 80%;
            margin-top: 20px;
            margin-bottom: 20px;
            margin-right:10px;
            flex: 1;
            min-width: 300px;
            min-height: 225px;
            
            background-color: rgba($purple-10, 0.2); // 50% opacity
            border-radius: 8px;
            display: flex;
            justify-content: center;
        }
    }
}