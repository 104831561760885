@import "../../../../common/variables";
.researcher-view-div {
  height: 100%;
  min-height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .breadcrumb {
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: -10px;
    align-self: stretch;
    color: var(--purple-50, #9380B6);
    /* subtittle */
    font-family: $campton-semi-bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .researcher-card-group-div {
    width: 100%; 

    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: flex-start;

    // max-height: 1080px;
    max-height: 1117px;
    overflow-y: auto;

  }

  .researcher-view-custom-spin{
    margin-top: 190px;
    position: absolute;
    left: 50%; 
    transform: translate(0%, -50%);
  }
  // .researcher-card-group-div.is-empty {
  //   justify-content: center; 
  //   align-items: center; 
  // }
  .is-empty{
    width: 100%;
  }

  .researcher-view-pagination{
    // display: flex;
    // justify-content: right; 
    // margin-bottom: 30px; 
    // margin-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    margin-bottom: 30px; 
  }
}

