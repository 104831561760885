@import "../../../../common/variables";

// .researcherCardDiv{
//     &:hover {
//       cursor: pointer;
//       transition: all 0.5s;
//       box-shadow: 0 2px 8px 0 rgb(233 231 242);
//     }

//     transition: 0.3s;
//     border-radius: 3px; /* 5px rounded corners */
//     // border-style: solid;
//     // border-width: 1px;
//     // border-color: #E9E9E9;
//     outline: 1px solid #E9E9E9;;
//     position: relative;
//     background: white;

    
//     display: flex;
//     flex-direction: column;

//     .top-div {        
//         display: flex;
//         flex-direction: row;
//         align-items: center;

//         .card-left-div {
//             padding: 0 8px;
            
//             display: flex;
//             flex-direction: column;
//             align-items: center;

//             .cardAvatar {
//                 padding: 8px;
//                 float: left;
//                 // margin-left: 20px;
//                 // margin-top: 23px;
//                 // margin-top: 33px;
        
//                 .cardJob {
//                     // font-size: 16px;
//                     // font-weight: normal;
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: rgba(0,0,0,0.45);
//                     letter-spacing: 0;
//                     text-align: center;
//                     line-height: 22px;
//                     margin-bottom: 2px;
//                     margin-top: 10px;
//                     max-width: 125px;
//                     overflow: hidden;
//                  }
//             }
//             .pIndexBadge {
//                 // align-self: flex-start;
//                 padding: 10px 8px;
//                 // position: absolute;
//                 // top: 10px;
//                 // right: 10px;
//                 background-color: white;
//                 border-radius: 50%;
//                 // padding: 5px 10px;
//                 font-size: 1.5em;
//                 font-weight: bold;
//                 color: $purple-80;
//             }
//         }
//         .card-right-div {
//             .cardText {
//                 padding: 12px 8px;
//                 float: left;
//                 // max-width: 220px;
//                 text-align: left;
//                 overflow: hidden;
        
//                 .cardName {
//                 //    font-size: 18px;
//                 //    font-weight: bold;
//                     font-family: $Ubuntu-bold;
//                     font-size: 18px;
//                     color: rgb(68, 68, 68);
//                     letter-spacing: 0;
//                     text-align: left;
//                     max-height: 24px;
//                     margin-bottom: 2px;
//                     overflow: hidden;
//                 }
//                 .cardName2 {
//                     //    font-size: 18px;
//                     //    font-weight: bold;
//                         font-family: $Ubuntu-bold;
//                         font-size: 19px;
//                         color: rgba(0,0,0,0.85);
//                         letter-spacing: 0;
//                         text-align: left;
//                         max-height: 24px;
//                         margin-bottom: 2px;
//                         overflow: hidden;
//                     }
        
//                 //  .cardJob {
//                 //     // font-size: 16px;
//                 //     // font-weight: normal;
//                 //     font-family: $open-sans-regular;
//                 //     font-size: 13px;
//                 //     color: rgba(0,0,0,0.45);
//                 //     letter-spacing: 0;
//                 //     text-align: left;
//                 //     line-height: 22px;
//                 //     margin-bottom: 2px;
//                 //     overflow: hidden;
//                 //  }
        
//                 .cardSchool {
//                     margin-top: 4px;
//                     // font-size: 16px;
//                     // font-weight: normal;
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: rgba(0,0,0,0.45);
//                     letter-spacing: 0;
//                     text-align: left;
//                     line-height: 22px;
//                     // margin-bottom: 2px;
//                     overflow: hidden;
//                  }
//                  .cardAffiliationName{
//                     font-family: $open-sans-regular;
//                     font-size: 15px;
//                     color: rgba(0,0,0,0.45);
//                     letter-spacing: 0;
//                     text-align: left;
//                     line-height: 22px;
//                     max-height: 25px;
//                     // margin-bottom: 2px;
//                     overflow: hidden;
//                     margin-bottom: 2px;
//                  }
//                  .cardDepartment {
//                     // font-size: 16px;
//                     // font-weight: normal;
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: rgba(0,0,0,0.45);
//                     letter-spacing: 0;
//                     text-align: left;
//                     line-height: 20px;
//                     margin-bottom: 4px;
//                     overflow: hidden;
//                  }
        
//                 .cardCitation {
//                     margin-top: 6px;
//                 //    font-size: 16px;
//                 //    font-weight: lighter;
//                    font-family: $open-sans-regular;
//                    font-size: 13px;
//                    color: $purple-60;
//                    line-height: 18px;
//                 //    float: left;
//                    margin-bottom: 4px;
//                    overflow: hidden;
//                 }
//                 .cardPatentCitations{
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: $purple-60;
//                     line-height: 18px;
//                     width: 210px;
//                     //    float: left;
//                     margin-bottom: 2px;
//                     overflow: hidden;
//                 }
//                 .cardPaper{
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: $purple-60;
//                     line-height: 18px;
//                     width: 210px;
//                     //    float: left;
//                     margin-bottom: 2px;
//                     overflow: hidden;
//                 }
        
//                 .cardPIndex {
//                     // font-size: 16px;
//                     // font-weight: lighter;
//                     // max-width: 80px;
//                     // text-overflow: ellipsis;
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: $purple-60;
//                     line-height: 18px;
//                     width: 220px;
//                     // float: left;
//                     // margin-left: 12px;
//                     margin-bottom: 14px;
//                     overflow: hidden;
//                  }
        
//                  .cardInventions {
//                     // font-size: 16px;
//                     // font-weight: lighter;
//                     // max-width: 80px;
//                     // text-overflow: ellipsis;
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: $purple-60;
//                     line-height: 18px;
//                     float: left;
//                     // margin-bottom: 10px;
//                     // width: 220px;
//                     width: 110px;
//                     height: 20px;
//                     overflow-x: hidden;
//                     overflow-y: hidden;
//                  }
//                  .cardPatents {
//                     // font-size: 16px;
//                     // font-weight: lighter;
//                     // max-width: 80px;
//                     // text-overflow: ellipsis;
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: $purple-60;
//                     line-height: 18px;
//                     float: left;
//                     margin-left: 10px;
//                     // margin-bottom: 10px;
//                     // width: 220px;
//                     width: 90px;
//                     height: 20px;
//                     overflow-x: hidden;
//                     overflow-y: hidden;
//                  }
//             }
//         }
//     }
//     .buttom-div {
//         .cardDetail{
//             // position: absolute;
//             // bottom: 0; 
    
//             background: #a89fc940;
//             // border: 1px solid #E9E9E9;
//             // border-radius: 0 0 2px 2px;
    
//             outline: 1px solid rgb(233 231 242);
//             // outline: 1px solid #E9E9E9;
//             width: 100%;
//             height: 49px;
//             display: flex;
//             // align-items: flex-end;
//             align-items: center;
            
    
//             .cardDetailText {
//                 font-family: $open-sans-regular;
//                 font-size: 14px;
//                 color: rgba(0,0,0,0.45);
//                 line-height: 22px;
//                 margin-left: 20%;
//                 display:flex;
//                 align-items:center;
//                 // margin-bottom: 12px;
//             }
    
//             .cardDetailLine{
//                 background-image: url(../../../../../assets/cardDetails.png);
//                 background-repeat: no-repeat;
//                 background-size: 100%;
//                 height: 16px;
//                 width: 2px;
//                 margin-left: 20%;
//                 // margin-bottom: 15px;
//             }
    
//             // .cardDetailIcon{
//             //     background-image: url(../../../../assets/cardDetails.png);
//             //     background-repeat: no-repeat;
//             //     // background-size: 100%;
//             //     height: 24px;
//             //     width: 116px;
//             //     margin-left: 7%;
//             //     margin-bottom: 10px;
//             // }
//             .cardDetailIconList{
//                 margin-left: 20px;
//                 margin-top: 5px;
//                 // margin-bottom: 20px;
    
//                 .cardDetailIcon1{
//                     background-image: url(../../../../../assets/websiteIcon.png);
//                     background-repeat: no-repeat;
//                     background-size: contain;
//                     height: 24px;
//                     width: 24px;
//                     display: inline-block;
//                     // margin-right: 10px;
//                     margin-left: 5px;
//                   }
                  
//                   .cardDetailIcon2{
//                     background-image: url(../../../../../assets/phoneIcon.png);
//                     background-repeat: no-repeat;
//                     background-size: contain;
//                     height: 24px;
//                     width: 24px;
//                     display: inline-block;
//                     // margin-right: 10px;
//                     margin-left: 22px;
//                   }
                  
//                   .cardDetailIcon3{
//                     background-image: url(../../../../../assets/emailIcon.png);
//                     background-repeat: no-repeat;
//                     background-size: contain;
//                     height: 24px;
//                     width: 24px;
//                     display: inline-block;
//                     margin-left: 22px;
//                   }
                  
//             }
            
//         }
//     }
// }


.lollipop-circle {
    &:hover {
      cursor: pointer;
      transition: all 0.5s;
      box-shadow: 0 2px 8px 0 rgb(233 231 242);
    }
}
.barchart-tooltip {
    transition: all 0.5s;

    left: 15%;
    // width: 85%;
    opacity: 0;
    
    font-size: 11px;

    padding: 10px;
    box-shadow: 0 2px 8px 0 rgba(206, 206, 206, 0.642);
    background-color: #F7F9FA;
    text-align: left;
    position: absolute;
    .cardTitle-tooltip-text{
        margin: 10px;
        font-family: AlibabaPuHuiTi-Regular;
        font-size: 12px;
        color: #696969;
    }
}