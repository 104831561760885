@import "../../../common/variables";

.three-finder-page-div {
  margin-top: 36px;
  margin-left: 18px;
  margin-right: 18px;


  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  
  .finder-card-div {
    position: relative; 

    margin: 36px 18px 36px 18px ;
    width: calc((100% - 18px * 6) / 3);
    

    // style-2: no background image
    .background-div {
      img {
        width: 100%;
        border-radius: 6px 6px 6px 6px;
        filter: brightness(95%);
        // filter: blur(0.7px) brightness(75%);

        object-fit: cover;
      }
    }
    .foreground-div {
      // padding: 24px;

      display: flex;
      flex-direction: column;
      justify-content: flex-end; 

      .finder-name-div {
        margin-top: 8px;
        
        color: #2e2e2e;
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;

        font-family: $Ubuntu-bold;
      }
      .finder-description-div {
        color: rgba(66, 66, 66, 0.65);
        margin-top: 8px;
        font-size: 14px;
        line-height: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        font-family: $Ubuntu-regular;
      }
      .button-div {
        margin-top: 8px;
        .button {
          width: 100px;
          height: 32px;
          padding: 12px;
          border-radius: 50px;
          border: 1px solid #8073ae;
          color: #8073ae;
    
          font-size: 12px;
          font-family: $open-sans-regular;
    
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;


          -webkit-tap-highlight-color: transparent;
          -webkit-appearance: button;
          background-color: white;
          background-image: none;
          cursor: pointer;
          font-family: $open-sans-light, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
            Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
            Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
          font-size: 100%;
          font-weight: 900;
          line-height: 1.5;
          letter-spacing: 1.5px;
          margin: 0;
          // -webkit-mask-image: -webkit-radial-gradient(#393939, #8073ae);
          padding: 0;
          text-transform: uppercase;
        }

        .button:hover {
          transition: all 0.5s;
          color: white !important; /* Change font color to black on hover */
          background-color: #8073ae;
        }

        // // https://ui-buttons.web.app/tilted-diagonal
        // .button,
        // .button *,
        // .button :after,
        // .button :before,
        // .button:after,
        // .button:before {
        //   border: 0 solid;
        //   box-sizing: border-box;
        // }
        // .button {
        //   -webkit-tap-highlight-color: transparent;
        //   -webkit-appearance: button;
        //   background-color: #8073ae;
        //   background-image: none;
        //   color: #fff; /* Initial font color */
        //   cursor: pointer;
        //   font-family: $open-sans-light, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
        //     Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
        //     Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        //   font-size: 100%;
        //   font-weight: 900;
        //   line-height: 1.5;
        //   letter-spacing: 1.5px;
        //   margin: 0;
        //   // -webkit-mask-image: -webkit-radial-gradient(#393939, #8073ae);
        //   padding: 0;
        //   text-transform: uppercase;
        // }
        
        // .button:disabled {
        //   cursor: default;
        // }
        
        // .button:-moz-focusring {
        //   outline: auto;
        // }
        
        // .button svg {
        //   display: block;
        //   vertical-align: middle;
        // }
        
        // .button [hidden] {
        //   display: none;
        // }
        
        // .button {
        //   border-radius: 99rem;
        //   border-width: 2px;
        //   overflow: hidden;
        //   padding: 0.8rem 3rem;
        //   position: relative;
        // }
        
        // .button:before {
        //   background: #fff;
        //   color:rgb(59, 59, 59);
        //   content: "";
        //   display: block;
        //   height: 100%;
        //   left: -100%;
        //   position: absolute;
        //   top: 0;
        //   transform: skew(0deg) translateX(-20%);
        //   transition: transform 0.2s ease;
        //   width: 120%;
        // }
        
        // .button:hover:before {
        //   transform: skew(45deg) translateX(75%);
        // }
        
        // .button:hover {
        //   color: white !important; /* Change font color to black on hover */
        // }

      }
    }



    // // style-1
    // .background-div {
    //   position: absolute;
    //   z-index: 1;
      
    //   img {
    //     width: 100%;
    //     border-radius: 6px 6px 6px 6px;
    //     filter: blur(0.7px) brightness(75%);

    //     object-fit: cover;
    //   }
    // }
    // .foreground-div {
    //   padding: 24px;

    //   position: absolute;
    //   z-index: 2;

    //   display: flex;
    //   flex-direction: column;
    //   justify-content: flex-end; 

    //   .NewArrivalCard__mask___94nsG {
    //     position: absolute;
    //     // bottom: -1px;
    //     height: 33.33%;
    //     width: 100%;
    //     z-index: 2;

    //     background-image: linear-gradient(transparent, rgb(36, 50, 21));
    //   }

    //   .finder-name-div {
    //     color: #fff;
    //     font-weight: 600;
    //     font-size: 24px;
    //     line-height: 28px;

    //     font-family: $open-sans-semi-bold;
    //   }
    //   .finder-description-div {
    //     color: rgba(255, 255, 255, .65);
    //     margin-top: 8px;
    //     font-size: 14px;
    //     line-height: 20px;
    //     display: -webkit-box;
    //     -webkit-line-clamp: 2;
    //     -webkit-box-orient: vertical;
    //     overflow: hidden;
    //     text-overflow: ellipsis;

    //     font-family: $open-sans-light;
    //   }
    // }
  }
}