@import "../../../../common/variables";

.custom-popover .ant-popover-inner  {
    font-family: $open-sans-regular;
    font-size: 14px;
    color: #696969;
    transition: 0.5s;
  }

.paperCardDiv{
    // width:458.5px;
    // margin-left: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    //flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    // border: 1px solid var(--pruple-30, #B6ACD1);

    position: relative;
    height: 193px;
    flex-direction: row;
    background: var(--white, #F7F7F7);


    &:hover {
        cursor: pointer;
        transition: all 0.5s;
        box-shadow: 0 2px 8px 0 rgb(233 231 242);
        background: url(<../../../../../../../assets/10-people-finder-highlight-bg/bg-paper.png) lightgray 50% / cover no-repeat;
        //background: linear-gradient(135deg, #a093b6 0%, #ffffff 100%);

        .card-right-div {
            border-radius: 8px;
            border: 1px solid var(--green, #72A84D);
            background: var(--purple-10, #E4E0EE);
        }
    }

    .card-left-div {
        display: flex;
        padding: 16px 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
        // margin-top: 10px;
        // min-width: 350px;

        .card-metadata {
            display: flex;
            height: 22px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            align-self: stretch;

            .card-year {
                display: flex;
                padding: 4px 8px;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                background: var(--purple-50, #9380B6);

                .card-year-text {
                    width: 38px;
                    height: 14px;
                    color: var(--white, #F7F7F7);
                    font-family: $campton-book;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .card-mag {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                flex: 1 0 0;
                overflow: hidden;
                color: var(--green, #72A84D);
                text-overflow: ellipsis;
                font-family: $campton-book;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                .card-mag-name {
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    color: var(--purple-110, #482476);
                    text-overflow: ellipsis;
                    font-family: $campton-book;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
        .card-title {
            width: 100%;
            .card-title-text{
                height: 38px;
                align-self: stretch;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;

                overflow: hidden;
                color: var(--purple-110, #482476);
                text-overflow: ellipsis;
                font-family: $campton-medium;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .card-title-tooltip{
                overflow: hidden;
                transition: all 0.3s;
                left: 15%;
                width: 85%;
                opacity: 0;
                
                font-size: 11px;
                box-shadow: 0 2px 8px 0 rgba(206, 206, 206, 0.642);
                background-color: #F7F9FA;
                text-align: left;
                position: absolute;

                .card-title-tooltip-text{
                    //white-space: nowrap;
                    margin: 10px;
                    font-family: $campton-light;
                    font-size: 12px;
                    color: #696969;
                }
                
            }
            
        }
        .card-title:hover .card-title-tooltip {
            transition: all 0.3s;
            width: 85%;
            left: 15%;
            opacity: 1;
        }

        .card-author {
            overflow: hidden;
            color: var(--purple-110, #482476);
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: $campton-book;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .card-citation {
            display: flex;
            height: 29px;
            padding: 7px 8px;
            justify-content: flex-end;
            align-items: center;
            gap: 12px;
            flex: 1 0 0;
            border-radius: 14px;
            background: #FFF;

            .pound-sign {
                width: 9.423px;
                height: 10px;
                fill: var(--purple-50, #9380B6);
            }

            .index-name {
                flex: 1 0 0;
                color: var(--purple-50, #9380B6);
                font-family: $campton-book;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .index-value {
                width: 28px;
                height: 15px;
                color: var(--green, #72A84D);
                font-family: $campton-medium;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        .card-doi {
            display: block;
            width: auto;
            max-width: 262px;
            align-self: stretch;
            white-space: nowrap;
            overflow: hidden; // Hide overflowed content
            white-space: nowrap; // Prevent the link from wrapping to the next line
            text-overflow: ellipsis; // Add ellipsis at the end of the content

            .card-doi-attr {
                color: var(--green, #72A84D);
                text-align: right;
                font-family: $campton-book;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .card-doi-arrow {
                color: var(--purple-110, #482476);
                font-family: $campton-book;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .card-doi-text {
                

                color: var(--purple-110, #482476);
                font-family: $campton-book;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-decoration-line: underline;
            }
        }

    }

    .card-right-div {
        display: flex;
        padding: 0px 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 8px;
        // border: 1px solid var(--pruple-30, #B6ACD1);
        // background: var(--white, #F7F7F7);

        background: var(--purple-10, #E4E0EE);

        .card-score-badge {
            color: var(--green, #72A84D);
            font-family: $campton-bold;
            font-size: 19px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}
  
// .paperCardDiv{
//     &:hover {
//         cursor: pointer;
//         transition: all 0.5s;
//         box-shadow: 0 2px 8px 0 rgb(233 231 242);
//     }

//     transition: 0.3s;
//     border-radius: 16px; 
//     outline: 1px solid rgb(233 231 242);
//     position: relative;
//     background: white;
    
//     // width: 100%;
//     display: flex;
//     flex-direction: row;
//     // justify-content: space-between;
//     align-items: flex-start;

//     .left-div {
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         .predictionScoreBadgeContainer {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             // padding-top: 10px;

//             .predictionScoreBadge {
//                 padding: 10px 8px;
//                 font-size: 1.5em;
//                 font-weight: bold;
//                 color: $purple-80;
//             }
//         }
//     }
//     .right-div {
//         padding: 12px 8px;

//         display: flex;
//         flex-direction: column; 
//         justify-content: flex-start; // space-between;
//         align-items: flex-start;

//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;


//         .cardTitleBlock{
//             margin-bottom: 8px;

//             width: 100%;
//             position: relative;
//             .cardTitleText {
//                 // cursor: pointer;
//                 overflow: hidden;
//                 text-overflow: ellipsis; 
//                 white-space: nowrap;

//                 display:flex;
//                 align-items:left;
//                 letter-spacing: 0;
//                 max-height: 24px;
//                 // line-height: 22px;

//                 font-family: $Ubuntu-bold;
//                 font-size: 18px;
//                 color: rgb(68, 68, 68);
//             }
//             .cardTitle-tooltip{
//                 overflow: scroll;
//                 transition: all 0.3s;
//                 left: 15%;
//                 width: 85%;
//                 opacity: 0;
                
//                 font-size: 11px;
//                 box-shadow: 0 2px 8px 0 rgba(206, 206, 206, 0.642);
//                 background-color: #F7F9FA;
//                 text-align: left;
//                 position: absolute;
//                 .cardTitle-tooltip-text{
//                     margin: 10px;
//                     font-family: AlibabaPuHuiTi-Regular;
//                     font-size: 12px;
//                     color: #696969;
//                 }
//             }
//         }
//         .cardTitleBlock:hover .cardTitle-tooltip {
//             transition: all 0.3s;

//             width: 85%;
//             left: 15%;
//             opacity: 1;
//         }

//         .cardNovelty, .cardSciCitation, .cardYear, .cardMagName, .cardPaperDoi, .cardAuthorsName {
//             // height: 32px; 
//             text-align: left;
//             // line-height: 28px;
//             // margin-top: 4px;
//             margin: 4px 0 4px 0;
//             max-width: 450px;
//             white-space: nowrap;
//             overflow: hidden;
//             text-overflow: ellipsis;
            
//             display: flex;
//             flex-direction: row; 

//             .attr-name {
//                 width: 120px;
//                 height: 100%;
                
//                 .cardTextName{
//                     background-color: $purple-60; 
//                     border-radius: 6px; 
//                     padding: 0 6px; 
//                     margin: 0 8px 0 0;
//                     float: left;
//                     font-family: $open-sans-regular;
//                     font-size: 12px;
//                     color: white; // $purple-60;
//                     // line-height: 18px;
//                 }
//             }
//             .cardTextName::after {
//                 content: '\00a0';
//             }
//             .cardNoveltyText, .cardSciCitationText, .cardYearNameText, .cardMagNameText, .cardDoiText, .cardAuthorsNameText{
//                 margin-left: 2px;
//                 font-family: $open-sans-regular;
//                 font-size: 12px;
//                 color: $purple-60;
//                 // line-height: 18px;
//                 // font-family: AlibabaPuHuiTi-Regular;
//                 // font-size: 14px;
//                 // color: #98ACC0;
//                 // line-height: 18px;
//                 white-space: nowrap;
//                 overflow: hidden;
//                 text-overflow: ellipsis;

//             }

//             .cardDoiText {
//                 a {
//                     color: $purple-60;
//                     text-decoration: underline;
//                 }
//             }
//             .cardAuthorsNameText{
//                 text-decoration: underline;
//             }
//         }
//     }

//     .cardText {
//         flex: 1;
//         margin-left: 18px;
//         margin-top: 10px;
//         max-width: 450px;
//         text-align: left;



//         .cardAuthor-tooltip{
//             display: none;
//         }
//     }





//     .cardAuthorsName:hover ~ .cardAuthor-tooltip {
//         width: 80%;
//         left: 55%;
//         transform: translate(70px, -30px);
//         display: block;
//         font-size: 11px;
//         box-shadow: 0 2px 8px 0 rgba(206, 206, 206, 0.642);
//         background-color: #F7F9FA;
//         z-index: 10;

//         .cardAuthor-tooltip-text{
//             margin-left: 10px;
//             font-family: AlibabaPuHuiTi-Regular;
//             font-size: 11px;
//             color: rgba(0,0,0,0.45);
//         }
//     }
// }