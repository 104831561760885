@import "../../../../common/variables";
// /* Default unchecked state */
// .ant-checkbox-inner {
//     background-color: #4D4593; 
//     border-color: #4D4593; 
// }

// /* Checked state - turns purple */
// .ant-checkbox-checked .ant-checkbox-inner {
//     background-color: #4D4593; 
//     border-color: #4D4593; 
// }

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4D4593;
    border-color: #4D4593;
}

.ant-checkbox-inner::after {
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
}

.remember-checkbox .ant-checkbox-wrapper .ant-checkbox + span {
    font-family: $campton-semi-bold;
    color: #4D4593; 
}

.ant-checkbox-inner::after {
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
}
::placeholder{
    font-family: $campton-semi-bold;
    color: var(--pruple-30, #B6ACD1) !important;
}
.branding-section::before {
    content: "";
    position: fixed;
    position: absolute;
    top: 0;
    left: 0;
    width: 59%; 
    height: 100%;
    background:#482476;
    transform: skew(-12.97deg);
    transform-origin:top;
}
.login-wrapper {
    display: flex;
    height: 100vh;
    width: 100vw;
    font-family: $campton-semi-bold;
    
    
    
    .branding-section {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: #F7F7F7;
        

        .branding-container {
            margin-left: 40px;
            text-align: left;
            position: relative; //Ensure content is above the slanted background 

            .branding-text {
                font-size: 64px;
                //font-weight: bold;
                margin-bottom: 10px;
            }

            .branding-tagline {
                font-size: 36px;
                //font-weight: bold;
            }
        }
        .branding-copyright{
            color: white;
            position: absolute; //Positions the element relative to its nearest positioned ancestor 
            bottom: 35px; 
            text-align: left;
            margin-left:-420px;
        }
    }


    .content-wrapper {
        flex: 1;
        display: flex;
        justify-content: right;
        align-items: center;
        background-color: #F7F7F7;


        .login-form {
            
            width: 550px;
            .login-text{
                //font-weight: bold;
                font-size: 35px;
                margin-bottom: 30px;
                color: #482476;
            }

            // padding: 20px;
            // background: white;
            // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            
            .username-box, .password-box {
                margin-bottom: 15px;
            }

            .remember-checkbox {
                margin-bottom: 15px;
            }

            .login-form-button {
                //font-weight: bold;
                font-family: $campton-semi-bold;
                font-size: 18px;
                width: 100%;
                height: 40px;
                background-color: #5B3B8C; // Purple background
                color: white; // White text
                border: solid white;
                border-width: 1.6px;;
                box-shadow: 2px 2px 2px rgba(140, 135, 135, 0.5);
                &:hover, &:focus {
                    background-color: darken(#5B3B8C, 10%); // Slightly darker purple on hover/focus
                }
            }

            .login-form-footer {
                display: flex;
                justify-content: flex-end;
                margin-top: -15px;
                margin-right: 185px;
                font-weight: bold;

                .link {
                    color: #4D4593;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

        }
    }
}


