@import "../../../../common/variables";

.patentCardDiv {  
    display: flex;
    align-items: flex-start;
    //flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: var(--white, #F7F7F7);

    position: relative;
    flex-direction: column;

    height:100%;
    width: 100%;

    &:hover {
        cursor: pointer;
        transition: all 0.5s;
        box-shadow: 0 2px 8px 0 rgb(233 231 242);
        background: url('../../../../../assets/10-people-finder-highlight-bg/bg-paper.png') lightgray 50% / cover no-repeat;
        // background: linear-gradient(135deg, #a093b6 0%, #ffffff 100%);
      }
      
    .card-top-div {
        display: flex;
        padding: 16px 12px;
        flex-direction: column;
        justify-content: space-between;
        //align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
        width: 100%;
      .card-metadata {
        .card-year {
            width: 50px;
            display: flex;
            padding: 4px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: var(--purple-50, #9380B6);
          .card-year-text {
            width: 38px;
            height: 14px;
            color: var(--white, #F7F7F7);
            font-family: $campton-semi-bold;
            font-size: 16px;
            line-height: normal;
          }
        }
  
        // Uncomment this section if decide to use the assignee info
        /*
        .card-assignee {
          .card-assignee-country {
            // Style for the assignee country
          }
          .card-assignee-type {
            // Style for the assignee type
          }
        }
        */
      }
  
      .card-title {
        width: 100%;
        .card-title-text {
            height: 38px;
            align-self: stretch;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            overflow: hidden;
            color: var(--purple-110, #482476);
            font-family: $campton-semi-bold;
            font-size: 16px;
            line-height: normal;
        }
        .card-title-tooltip{
            overflow: hidden;
            transition: all 0.3s;
            //left: 15%;
            top:0%;
            width: 85%;
            opacity: 0;
            
            font-size: 11px;
            box-shadow: 0 2px 8px 0 rgba(206, 206, 206, 0.642);
            background-color: #F7F9FA;
            text-align: left;
            position: absolute;

            .card-title-tooltip-text{
                //white-space: nowrap;
                margin: 10px;
                font-family: $campton-light;
                font-size: 12px;
                color: #696969;
            }
            
        }
        &:hover .card-title-tooltip {
            transition: all 0.3s;
            width: 85%;
            top:0%;
            //left: 15%;
            opacity: 1;
        }
        
      }
  
      .card-assignee-company {
        .card-assignee-company-text {
            font-family: $campton-bold;
            color: #482476;
            font-size: 12px;
            line-height: 17px;
            white-space: wrap;
        }
      }

      .card-assignee-university {
        .card-assignee-university-text {
            font-family: $campton-bold;
            color: #482476;
            font-size: 12px;
            line-height: 17px;
            white-space: wrap;
        }
      }
  
      .card-assignee-other {
        .card-assignee-other-text {
            font-family: $campton-bold;
            color: #482476;
            font-size: 12px;
            line-height: 17px;
            white-space: wrap;
        }
        
      }

      .card-assignee-name {
        .card-assignee-name-text {
          font-family: $campton-book;
          color: #482476;
          font-size: 12px;
          line-height: 17px;
          white-space: wrap;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .card-assignee-name-tooltip{
          overflow: hidden;
          transition: all 0.3s;
          left: 15%;
          width: 50%;
          opacity: 0;
          
          font-size: 11px;
          box-shadow: 0 2px 8px 0 rgba(206, 206, 206, 0.642);
          background-color: #F7F9FA;
          text-align: left;
          position: absolute;
  
          .card-assignee-name-tooltip-text{
              //white-space: nowrap;
              margin: 10px;
              font-family: $campton-light;
              font-size: 12px;
              color: #696969;
          }
          
        }
      }
      .card-assignee-name:hover .card-assignee-name-tooltip {
        transition: all 0.3s;
        width: 50%;
        left: 15%;
        opacity: 1;
        white-space: wrap;
    }
  
      .card-bottom-metadata {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        .card-app-where {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            flex: 1 0 0;

          .card-app-label-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;

            .card-app-label {
              color: var(--green, #482476);
              font-family: $campton-book;
              font-size: 12px;
            }
            .card-app-container {
              display: flex;
              width: 68px;
              height: 25px;
              padding: 4px 8px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
              //align-self: stretch;
              border-radius: 16px;
              background: var(--purple-10, #FFF);
  
              .card-app-badge {
                  color: var(--green, #A495C3);
                  font-family: $campton-book;
                  font-size: 12px;
              }
            }
            .card-where-container {
              display: flex;
              // width: 37px;
              // height: 25px;
              padding: 4px 8px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 10px;
              //align-self: stretch;
              border-radius: 16px;
              background: var(--purple-10, #FFF);
              .card-where-badge {
                  color: var(--green, #A495C3);
                  font-family: $campton-book;
                  font-size: 12px;
              }
            }
          }

        }
        
      }
      .card-weblink {

        color: #482476;
        .card-weblink-text a{
          text-decoration: underline;
          color: #482476;
          font-size: 12px;
        }

        display: flex;
        justify-content: flex-end;
      }
    }
  }
  