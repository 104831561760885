@import "../../../../common/variables";

.researcherCardDiv {
    

    
    // align-items: center;
    // flex-direction: row;
    // border-radius: 10px;
    overflow: hidden;
    // outline: 2px solid rgb(232, 230, 243);
    position: relative;
    background: #F7F7F7;

    display: flex;
    height: 261px;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--pruple-30, #B6ACD1);
    

    &:hover {
        cursor: pointer;
        transition: all 0.5s;
        box-shadow: 0 2px 8px 0 rgb(233 231 242);
        background: url(<../../../../../../../assets/10-people-finder-highlight-bg/bg-people.png) lightgray 50% / cover no-repeat;
        //background: linear-gradient(135deg, #a093b6 0%, #ffffff 100%); // need to adjust
    }

    .card-left-div {
        // flex: 3;
        // padding: 10px;
        // width: calc(100% - 300px);
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        // height: 100%;

        display: flex;
        padding: 24px 0px 8px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
        

        .researcher-details {
            // margin-top: 10px;
            // flex-direction: row;

            .cardName {
                // font-family: $Ubuntu-bold;
                // //font-size: 2vw;
                // //font-size: 24px;
                // font-weight: bold;
                // color: rgb(68, 68, 68);
                // letter-spacing: 0;
                text-align: left;
                // max-height: 24px;
                // margin-bottom: 10px;
                white-space: nowrap;
                font-size: clamp(14px, 2.5vw, 24px);
                // //overflow: hidden;

                // width: 198px;
                color: var(--purple-110, #482476);
                // text-align: center;
                font-family: $campton-semi-bold;
                // font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .cardDemographic{
                // display: flex; 
                // align-items: center; 
                // // justify-content: start;
                margin-top: 10px;
                // //flex-direction: row;
                // justify-content: start;

                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                
                .cardGender {
                    // font-size: 20px;
                    // font-weight: bold;
                    // background-color: #72A84D; // Change as per actual color code
                    // color: #ffffff;
                    border-radius: 50%;
                    // width: 35px;
                    // height: 35px;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;

                    //  // Ensure the circle does not shrink or grow
                    // min-width: 35px;
                    // min-height: 35px;
                    // max-width: 35px;
                    // max-height: 35px;

                    display: flex;
                    width: 32px;
                    height: 32px;
                    padding: 7px 8px;
                    justify-content: center;
                    align-items: center;
                    // border-radius: 16px;
                    background: var(--green, #72A84D);

                    .card-gender-letter{
                        // width: 20px;
                        // height: 22px;
                        margin-top: 5px;
                        flex-shrink: 0;
                        color: var(--white, #F7F7F7);
                        font-family: $campton-medium;
                        font-size: 23px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;

                    }
                    
                    
                }

                .cardTenure {
                    display: flex;
                    height: 28px;
                    flex-direction: column;
                    justify-content: center;
                    flex: 1 0 0;

                    overflow: hidden;
                    color: var(--purple-110, #482476);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-family: $campton-semi-bold;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                .cardSchool {
                    // font-size: 16px;
                    // font-weight: bold;
                    // // -webkit-box-orient: vertical;
                    // // -webkit-line-clamp: 2;  // Limit text to two lines
                    // color: #4D4593; // Default color for the entire text
                    // text-align: left;
                    letter-spacing: 0;
                    // max-height: 40px;
                    overflow: hidden;
                    // margin-left: 10px;
                    // width: 100%; // Ensure this container has a specific width

                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis;

                    height: 35px;
                    flex: 1 0 0;
                    
                    .schoolName {
                        overflow: hidden;
                        color: var(--green, #72A84D);
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        /* subtittle */
                        font-family: $campton-semi-bold;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                    
                    .departName {
                        // color: #4D4593;
                        // width: 100%;
                        overflow: hidden;
                        overflow: ellipsis;

                        color: var(--purple-110, #482476);
                        font-family: $campton-semi-bold;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
                
                
            }
            
        }

        .scisci-index-div-group {
            // display: flex;
            // flex-direction: column;
            // margin-top:10px;

            display: flex;
            padding-top: 8px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
            
      
            .scisci-index {
                // display: flex;
                // justify-content: space-between;
                // margin-top: 10px;
                // //padding: 10px;
                // padding: 5px 10px;
                // background-color: #ffffff;
                // border-radius: 20px;
                display: flex;
                height: 32px;
                padding: 7px 8px;
                justify-content: flex-end;
                align-items: center;
                gap: 12px;
                align-self: stretch;
                border-radius: 14px;
                background: #FFF;
                
                .pound-sign {
                    width: 9.423px;
                    height: 10px;
                    fill: var(--purple-50, #9380B6);
                }
                .index-name {
                    flex: 1 0 0;
                    color: var(--purple-50, #9380B6);
                    font-family: $campton-semi-bold;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                }
        
                .index-value {
                    color: var(--green, #72A84D);
                    font-family: $campton-medium;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
        }

        .cardDetail {
            align-self: stretch;
            // align-self: center; 
            // width: 100%;
            // text-align: center;
            // margin-top: 10px;

            .cardDetailText {
                // width: 100%;
                // font-family: $open-sans-regular;
                // font-size: 15px;
                // font-weight: bold;
                // color: #4D4593;
                // line-height: 22px;
                // display:flex;
                // align-items:center;
                // text-align: center;
                // justify-content: center;
                // text-decoration: underline;
                color: var(--purple-110, #482476);
                text-align: center;
                font-family: $campton-semi-bold;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-decoration-line: underline;
            }

            .cardDetailLine{
                width: 2%;
                background-image: url(../../../../../assets/cardDetails.png);
                background-repeat: no-repeat;
                background-size: 100%;
                height: 16px;
                width: 2px;
                
                display:flex;
                align-items:center;
                justify-content: center;
                
            }
        }
    }

    .card-right-div {
        // flex: 1;
        // padding: 20px;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;

        display: flex;
        width: 206px;
        height: 261px;
        padding: 16px 8px 16px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    
        .researcher-img-container {
            font-size: 80px;
            color: #9380B6;
            
            // position: absolute;
            // top: -30px;
            // right: -80px;
            // width: 240px;  // Adjust size as necessary
            // height: 240px;  // Must be equal to width to create a perfect circle
            // border-radius: 50%;  // Makes the div circular
            // overflow: hidden;  // Hides anything outside the circle
            // display: flex;
            // justify-content: center;  // Centers the image horizontally
            // align-items: center;  // Centers the image vertically
            // border: 2px solid #ffffff;  // Optional: adds a white border around the circle
            // box-shadow: 0 2px 4px rgba(0,0,0,0.1);  // Optional: adds subtle shadow for depth
            // background-color: #fff;  // Good for icons with transparency

            width: 187px;
            height: 187px;
            flex-shrink: 0;

            .researcher-icon {
                margin-top: 50px;
                margin-left: 80px;
                margin-bottom: 20px;
                font-size: 80px;
                color: #6f4d4d;
            }
            
            .img-ellipse {
                position: absolute;
                top: -30px;
                right: -80px;
                width: 240px;  // Adjust size as necessary
                height: 240px;  // Must be equal to width to create a perfect circle
                border-radius: 50%;  // Makes the div circular
                overflow: hidden;  // Hides anything outside the circle
                display: flex;
                // justify-content: center;  // Centers the image horizontally
                align-items: center;  // Centers the image vertically
                // border: 2px solid #ffffff;  // Optional: adds a white border around the circle
                // box-shadow: 0 2px 4px rgba(0,0,0,0.1);  // Optional: adds subtle shadow for depth
                background-color: #fff;  // Good for icons with transparency
                border: 1px solid var(--pruple-30, #B6ACD1);

                span {
                    margin-left: 54px;
                }

                .researcher-photo {
                    height: 264px;
                    width: auto;
                    object-fit: cover;
                    // flex-shrink: 0;
                    margin-top: 60px;
                    margin-right: 80px;
                }
            }
            // .img-container {
            //     width: 190px;
            //     height: 264px;
            //     flex-shrink: 0;
            // }
            
        }
        
    
        .researcher-actions {
            // display: flex;
            // justify-content: space-around;
            // width: 100%;
            // margin-top: 200px;
            // flex-direction: row;
            // align-items: center;
            // margin-left: 0px;

            display: flex;
            align-items: flex-start;
            gap: 32px;
            
            .card-icon{
                // display: flex;
                // margin-top: 10px;
                // background-color: #4D4593;
                // border-radius: 10px;
                // height: 30px;
                // width: 30px;
                // align-items: center;
                // margin-left: 20px;
                // justify-content: center;

                display: flex;
                width: 30px;
                height: 30px;
                padding: 6.857px;
                justify-content: center;
                align-items: center;
                border-radius: 6.857px;
                border: 0.857px solid var(--purple-10, #E4E0EE);
                background: var(--purple-110, #482476);
                box-shadow: 1.714px 1.714px 3.429px 0px rgba(1, 1, 1, 0.24), -1.714px -1.714px 5.143px 0px rgba(255, 161, 161, 0.25);


                .action-icon {
                    // font-size: 20px;
                    color: #ffffff;
                    width: 16.749px;
                    height: 16.172px;
                    flex-shrink: 0;
                    margin-left:3px;
                }
            }
            
        }
    }
    
}


// .researcherCardDiv{
//     &:hover {
//       cursor: pointer;
//       transition: all 0.5s;
//       box-shadow: 0 2px 8px 0 rgb(233 231 242);
//     }

//     transition: 0.3s;
//     border-radius: 16px; /* 5px rounded corners */
//     // border-style: solid;
//     // border-width: 1px;
//     // border-color: #E9E9E9;
//     outline: 1px solid #E9E9E9;
//     position: relative;
//     background: white;

//     display: flex;
//     flex-direction: column;

//     .top-div {        
//         display: flex;
//         flex-direction: row;
//         align-items: center;

//         .card-left-div {
//             padding: 0 8px;
            
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: center;

//             .cardAvatar {
//                 padding: 8px;
//                 float: left;
//                 // margin-bottom: 60px;

//                 //height: 128px;
//                 // margin-left: 20px;
//                 // margin-top: 23px;
//                 // margin-top: 33px;
        
//                 // .cardJob {
//                 //     // font-size: 16px;
//                 //     // font-weight: normal;
//                 //     font-family: $open-sans-regular;
//                 //     font-size: 13px;
//                 //     color: rgba(0,0,0,0.45);
//                 //     letter-spacing: 0;
//                 //     text-align: center;
//                 //     line-height: 22px;
//                 //     margin-bottom: 2px;
//                 //     margin-top: 10px;
//                 //     max-width: 125px;
//                 //     overflow: hidden;
//                 //  }
//             }
//             .pIndexBadge {
//                 padding: 10px 8px;
//                 background-color: white;
//                 border-radius: 50%;
//                 font-size: 1.5em;
//                 font-weight: bold;
//                 color: $purple-80;
//             }
//         }
//         .card-right-div {
//             width: calc(100% - 162px);

//             .cardText {
//                 width: 100%; 

//                 padding: 12px 8px;
//                 float: left;
//                 // max-width: 220px;
//                 text-align: left;
//                 overflow: hidden;
        
//                 .cardName {
//                 //    font-size: 18px;
//                 //    font-weight: bold;
//                     font-family: $Ubuntu-bold;
//                     font-size: 18px;
//                     color: rgb(68, 68, 68);
//                     letter-spacing: 0;
//                     text-align: left;
//                     max-height: 24px;
//                     margin-bottom: 2px;
//                     overflow: hidden;
//                 }
//                 .cardName2 {
//                     //    font-size: 18px;
//                     //    font-weight: bold;
//                         font-family: $Ubuntu-bold;
//                         font-size: 19px;
//                         color: rgba(0,0,0,0.85);
//                         letter-spacing: 0;
//                         text-align: left;
//                         max-height: 24px;
//                         margin-bottom: 2px;
//                         overflow: hidden;
//                     }
        
//                 //  .cardJob {
//                 //     // font-size: 16px;
//                 //     // font-weight: normal;
//                 //     font-family: $open-sans-regular;
//                 //     font-size: 13px;
//                 //     color: rgba(0,0,0,0.45);
//                 //     letter-spacing: 0;
//                 //     text-align: left;
//                 //     line-height: 22px;
//                 //     margin-bottom: 2px;
//                 //     overflow: hidden;
//                 //  }
        
//                 .cardSchool {
//                     // height: 32px;
//                     margin-top: 4px;
//                     // font-size: 16px;
//                     // font-weight: normal;
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: rgba(0,0,0,0.45);
//                     letter-spacing: 0;
//                     text-align: left;
//                     line-height: 22px;
//                     // margin-bottom: 2px;
//                     overflow: hidden;
//                  }
//                  .cardAffiliationName{
//                     font-family: $open-sans-regular;
//                     font-size: 15px;
//                     color: rgba(0,0,0,0.45);
//                     letter-spacing: 0;
//                     text-align: left;
//                     line-height: 22px;
//                     max-height: 25px;
//                     // margin-bottom: 2px;
//                     overflow: hidden;
//                     margin-bottom: 2px;
//                  }
//                  .cardDepartment {
//                     // font-size: 16px;
//                     // font-weight: normal;
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: rgba(0,0,0,0.45);
//                     letter-spacing: 0;
//                     text-align: left;
//                     line-height: 20px;
//                     margin-bottom: 4px;
//                     overflow: hidden;
//                  }
        
//                 .scisci-index-div-group {
//                     width: 100%;

//                     .scisci-index {
//                         margin: 8px 0;
//                         display: flex;
//                         flex-direction: row;
//                         flex-wrap: nowrap;

//                         background-color: #f7f6fa;
//                         // border: 2px solid;
//                         border-radius: 10px;

//                         .index-name {
//                             padding: 8px;
//                             font-family: $open-sans-regular;
//                             font-size: 14px;
//                             color: #858585;
//                             line-height: 18px;
//                             margin-bottom: 2px;
//                             overflow: hidden;
//                         }
//                         .index-value {
//                             padding: 8px;
//                             font-family: $open-sans-regular;
//                             font-size: 16px;
//                             font-weight: bold;
//                             color: #2f3035;
//                             line-height: 18px;
//                             margin-bottom: 2px;
//                             overflow: hidden;
//                         }
//                     }

//                     // .cardCitation {
//                     //     margin-top: 6px;
//                     // //    font-size: 16px;
//                     // //    font-weight: lighter;
//                     //    font-family: $open-sans-regular;
//                     //    font-size: 13px;
//                     //    color: $purple-60;
//                     //    line-height: 18px;
//                     // //    float: left;
//                     //    margin-bottom: 4px;
//                     //    overflow: hidden;
//                     // }
//                     // .cardInventions {
//                     //     // font-size: 16px;
//                     //     // font-weight: lighter;
//                     //     // max-width: 80px;
//                     //     // text-overflow: ellipsis;
//                     //     font-family: $open-sans-regular;
//                     //     font-size: 13px;
//                     //     color: $purple-60;
//                     //     line-height: 18px;
//                     //     float: left;
//                     //     // margin-bottom: 10px;
//                     //     // width: 220px;
//                     //     width: 110px;
//                     //     height: 20px;
//                     //     overflow-x: hidden;
//                     //     overflow-y: hidden;
//                     //  }
//                     //  .cardLeadAuthorPapers {
//                     //     // font-size: 16px;
//                     //     // font-weight: lighter;
//                     //     // max-width: 80px;
//                     //     // text-overflow: ellipsis;
//                     //     font-family: $open-sans-regular;
//                     //     font-size: 13px;
//                     //     color: $purple-60;
//                     //     line-height: 18px;
//                     //     float: left;
//                     //     margin-bottom: 10px;
//                     //     width: 240px;
//                     //     //width: 110px;
//                     //     //height: 40px;
//                     //     overflow-x: hidden;
//                     //     overflow-y: hidden;
//                     //  }
//                 }
//                 .cardPatentCitations{
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: $purple-60;
//                     line-height: 18px;
//                     width: 210px;
//                     //    float: left;
//                     margin-bottom: 2px;
//                     overflow: hidden;
//                 }
//                 .cardPaper{
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: $purple-60;
//                     line-height: 18px;
//                     width: 210px;
//                     //    float: left;
//                     margin-bottom: 2px;
//                     overflow: hidden;
//                 }
//                 .cardPIndex {
//                     // font-size: 16px;
//                     // font-weight: lighter;
//                     // max-width: 80px;
//                     // text-overflow: ellipsis;
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: $purple-60;
//                     line-height: 18px;
//                     width: 220px;
//                     // float: left;
//                     // margin-left: 12px;
//                     margin-bottom: 14px;
//                     overflow: hidden;
//                  }

//                  .cardPatents {
//                     // font-size: 16px;
//                     // font-weight: lighter;
//                     // max-width: 80px;
//                     // text-overflow: ellipsis;
//                     font-family: $open-sans-regular;
//                     font-size: 13px;
//                     color: $purple-60;
//                     line-height: 18px;
//                     float: left;
//                     margin-left: 10px;
//                     // margin-bottom: 10px;
//                     // width: 220px;
//                     width: 90px;
//                     height: 20px;
//                     overflow-x: hidden;
//                     overflow-y: hidden;
//                  }
//             }
//         }
//     }
//     .buttom-div {
//         .cardDetail{
//             position: absolute;
//             bottom: 0; 
            
//             display: flex;
//             flex-direction: row;
//             flex-wrap: nowrap;
    
//             background: #a89fc940;
//             border-radius: 16px;
//             outline: 1px solid rgb(233 231 242);
//             width: 100%;
//             height: 49px;
//             display: flex;
//             align-items: center;
    
//             .cardDetailText {
//                 width: 49%;

//                 font-family: $open-sans-regular;
//                 font-size: 14px;
//                 color: rgba(0,0,0,0.45);
//                 line-height: 22px;

//                 display:flex;
//                 align-items:center;
//                 justify-content: center;

//                 // .button {
//                 //     border-radius: 16px;
//                 //     background-color: $purple-60;
//                 //     padding: 8px 28px;
//                 //     color: white;
//                 //     font-weight: bold;
//                 // }

//             }
    
//             .cardDetailLine{
//                 width: 2%;
//                 background-image: url(../../../../../assets/cardDetails.png);
//                 background-repeat: no-repeat;
//                 background-size: 100%;
//                 height: 16px;
//                 width: 2px;
                
//                 display:flex;
//                 align-items:center;
//                 justify-content: center;
//             }
    
//             // .cardDetailIcon{
//             //     background-image: url(../../../../assets/cardDetails.png);
//             //     background-repeat: no-repeat;
//             //     // background-size: 100%;
//             //     height: 24px;
//             //     width: 116px;
//             //     margin-left: 7%;
//             //     margin-bottom: 10px;
//             // }
//             .cardDetailIconList{
//                 width: 49%;
//                 margin-left: 20px;
//                 margin-top: 5px;
//                 // margin-bottom: 20px;

//                 display:flex;
//                 align-items:center;
//                 justify-content: center;
    
//                 .cardDetailIcon1{
//                     background-image: url(../../../../../assets/websiteIcon.png);
//                     background-repeat: no-repeat;
//                     background-size: contain;
//                     height: 24px;
//                     width: 24px;
//                     display: inline-block;
//                     // margin-right: 10px;
//                     margin-left: 5px;
//                   }
                  
//                   .cardDetailIcon2{
//                     background-image: url(../../../../../assets/phoneIcon.png);
//                     background-repeat: no-repeat;
//                     background-size: contain;
//                     height: 24px;
//                     width: 24px;
//                     display: inline-block;
//                     // margin-right: 10px;
//                     margin-left: 22px;
//                   }
                  
//                   .cardDetailIcon3{
//                     background-image: url(../../../../../assets/emailIcon.png);
//                     background-repeat: no-repeat;
//                     background-size: contain;
//                     height: 24px;
//                     width: 24px;
//                     display: inline-block;
//                     margin-left: 22px;
//                   }
                  
//             }
            
//         }
//     }
// }