@import "../../../common/variables";

.people-finder-page-div {
  // background: #f1f1f1; 
  
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;

  width: 100%;
  background: var(--purple-50, #ffffff);
  // background: var(--purple-50, #9380B6);
  .left-div {
    margin: 0 8px 0 16px;
    width: 20%;
    height: 100%;
  }
  .right-div {
    margin: 0 16px 0 8px;
    width: calc(80% - 16px * 3);
  }

}